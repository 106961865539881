import React, { useState } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux";
function CustomBox({ item, choice, isChicked2, editable = true }) {
  const { colorMode } = useColorMode();
  let [isChicked, setIsChicked] = useState(isChicked2);
  const { language } = useSelector((state) => state.lang);
  return (
    <Box
      width={"fit-content"}
      fontWeight={"semibold"}
      rounded={"full"}
      cursor={"pointer"}
      onClick={() => {
        choice(item);
        if (editable) setIsChicked(!isChicked);
      }}
      color={isChicked ? "white" : colorMode === "dark" ? "white" : "black"}
      bgColor={
        isChicked ? "purple.400" : colorMode === "dark" ? "gray" : "transparent"
      }
      paddingX={"1rem"}
      py={".2rem"}
      border={"1px solid"}
      borderColor={"black"}
    >
      {language === "English" ? item.name.en : item.name.de}
    </Box>
  );
}

export default CustomBox;
