import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrderFromNotification } from "../order/orderSlice";
import axios from "axios";

import { BASEURL } from "../../utils/urls";
import { getTheError } from "../../utils/ErorrHandling";

const initialState = {
  isLoading: false,
  pages: 1,
  pageNumber: 1,
  notifications: [],
  total: 0,
};
export const markAllAsRead = createAsyncThunk(
  "notification/markAllAsRead",
  async (_, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${BASEURL}api/notification/mark-all-as-read`,
        {},
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotfications(state, { payload }) {
      state.notifications = [...payload];
      state.total = payload?.length;
    },
    addNotification(state, { payload }) {
      state.notifications.unshift(payload);

      state.total = state.notifications?.length;
    },
    removeNotification(state) {
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOrderFromNotification.fulfilled,
      (state, { payload }) => {
        // console.log(payload);
        state.total -= +payload?.data.data.isViewed;

        state.notifications = state.notifications.filter(
          (item) => item.id !== payload?.notificationId
        );
      }
    );
    builder.addCase(markAllAsRead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(markAllAsRead.fulfilled, (state) => {
      state.isLoading = false;
      state.notifications = [];
      state.total = 0;
    });
    builder.addCase(markAllAsRead.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export default notificationSlice.reducer;
export const { addNotification, getNotfications, removeNotification } =
  notificationSlice.actions;
