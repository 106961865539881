import React from "react";
import { Alert, Spin } from "antd";
const CustomLoading = ({ state }) => {
	return (
		<Spin spinning={state}>
			<Alert
				type="info"
				message="please wait"
				description="Your Order is Loading"
			/>
		</Spin>
	);
};
export default CustomLoading;
