import React, { useEffect, useState } from "react";
import Questions from "./Questions";
import { Box, Button, Container, Flex, Input } from "@chakra-ui/react";
import { FloatButton, Space } from "antd";
import { AddIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestion,
  buildTheUi,
  clearTheData,
  createProduct,
  getProduct,
  setProductToUpdate,
  updateProduct,
  // saveProduct,
} from "../../features/products/ProductSlice";
import { generateUUID } from "../../utils/Tools";
// import SelectTheProduct from "./SelectTheProduct";
import QuestionsWithoutInput from "./QuestionsWithoutInput";
import Loading from "../Loading";
import { Navigate, useLocation } from "react-router-dom";
function UpdateProduct() {
  const dispatch = useDispatch();
  const addQuestionHandler = () => {
    const question = {
      id: generateUUID(),
      parentID: "root",
      children: [],
      type: 1,
      value: { en: "", de: "" },
      thisIsNew: true,
    };
    dispatch(addQuestion(question));
    dispatch(buildTheUi());
  };
  // useEffect(() => {
  //   dispatch(buildTheUi());
  // }, [dispatch]);

  const location = useLocation();
  const product = location?.state?.item;
  const { buttonsText } = useSelector((state) => state.lang);
  const { ui, questions, answers, isLoading, updatedtedSuccessfuly } =
    useSelector((state) => state.products);

  const [productEng, setProductEng] = useState(product.name.en);
  const [productGer, setProductGer] = useState(product.name.de);
  const addHandler = () => {
    let ch = true;
    const item = {
      productId: product.id,
      data: {
        name: { en: productEng.trim(), de: productGer.trim() },
        questions: questions?.map((e) => {
          if (e === null || e === undefined) {
            ch = false;
            return;
          }
          if (e?.value["en"] === "" || e?.value["de"] === "") {
            ch = false;
            return;
          }
          return { ...e, children: [] };
        }),
        answers: answers?.map((e) => {
          if (e === null || e === undefined) {
            ch = false;
            return;
          }
          if (e?.value["en"] === "" || e?.value["de"] === "") {
            ch = false;
            return;
          }

          return { ...e, children: [] };
        }),
      },
    };
    if (ch === false) return;

    if (productEng !== "" && productGer !== "") {
      const finalData = {
        productId: item.productId,
        children: ui,
        isEdited: pNameChanged ? true : undefined,
        name: { en: productEng.trim(), de: productGer.trim() },
      };
      // console.log(item);
      // console.log(finalData);
      dispatch(updateProduct(finalData));
      // dispatch(clearTheData());
      dispatch(buildTheUi());
    }
  };
  const [pNameChanged, setPNameChanged] = useState(false);
  const handlerEng = (e) => {
    setPNameChanged(true);
    setProductEng(e.target.value);
  };
  const handlerGer = (e) => {
    setPNameChanged(true);
    setProductGer(e.target.value);
  };
  useEffect(() => {
    // dispatch(clearTheData());
    dispatch(getProduct(product.id));
    dispatch(setProductToUpdate(product.id));
    dispatch(buildTheUi());
  }, []);
  if (isLoading) return <Loading />;
  if (updatedtedSuccessfuly) return <Navigate to={"/success"} />;
  // console.log(product);
  return (
    <Box
      p={"1rem"}
      boxShadow={"md"}
      w={"90%"}
      minH={"75%"}
      position={"relative"}
      paddingBottom={"5rem"}
    >
      {/* <Flex w={"100%"}> */}
      <Flex w={"100%"} flexDir={"column"} gap={".5rem"}>
        <Button
          width={"fit-content"}
          alignSelf={"end"}
          variant={"outline"}
          onClick={addHandler}
        >
          {buttonsText.update}
        </Button>

        <Input
          value={productEng}
          placeholder="productName"
          onChange={handlerEng}
        ></Input>

        <Input
          value={productGer}
          placeholder="produktName"
          onChange={handlerGer}
        ></Input>
        {ui.map((item, index) => {
          return (
            <Questions
              item={item}
              key={index}
              num={`Q${index + 1}`}
              update={true}
              productId={product.id}
            />
          );
        })}
      </Flex>

      {/* </Flex> */}
      {/* <SelectTheProduct /> */}
      {ui.map((item, index) => {
        return (
          <QuestionsWithoutInput
            item={item}
            key={index}
            num={`Q${index + 1}`}
          />
        );
      })}
      <FloatButton
        style={{
          position: "absolute",
        }}
        onClick={addQuestionHandler}
        icon={<AddIcon />}
      ></FloatButton>
    </Box>
  );
}

export default UpdateProduct;
