import React from "react";
import DOMPurify from "dompurify";
function NewText({ rawContent }) {
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    if (node.tagName === "A") {
      // Ensure the link opens in a new tab and is secure
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
      node.setAttribute("style", "color:#674188;font-weight:bold");

      // Check if the href doesn't start with http:// or https://
      const href = node.getAttribute("href");
      if (href && !href.startsWith("http://") && !href.startsWith("https://")) {
        node.setAttribute("href", `http://${href}`);
      }
    }
  });
  const replaceIframeWithImg = (htmlString) => {
    return htmlString.replace(
      /<iframe[^>]*src="([^"]*)"[^>]*><\/iframe>/g,
      '<img src="$1" />'
    );
  };
  const modifiedMessage = replaceIframeWithImg(rawContent);
  //   console.log(modifiedMessage);
  const sanitizedContent = DOMPurify.sanitize(modifiedMessage, {
    ALLOWED_TAGS: [
      "b",
      "i",
      "u",
      "a",
      "strong",
      "em",
      "span",
      "p",
      "h1",
      "h2",
      "ul",
      "ol",
      "li",
      "img", // Allow img tags
    ],
    ALLOWED_ATTR: ["href", "target", "rel", "class", "style", "src"],
  });

  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
}

export default NewText;
