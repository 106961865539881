import React from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from "faker";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function LineChart({ colorMode }) {
  const { lines } = useSelector((store) => store.statistics);
  const result =
    lines === undefined
      ? {}
      : Object.entries(lines).map(([name, values]) => ({
          name,
          values: Object.values(values),
        }));
  if (result === undefined || result[0] === undefined) return <></>;
  const colors = [
    "rgb(255, 99, 132)",
    "rgb(53, 162, 235)",
    "rgb(53, 0, 235)",
    "rgb(53, 162, 2)",
    "rgb(153, 122, 205)",
  ];
  const bcolors = [
    "rgba(255, 99, 132, 0.5)",
    "rgba(53, 162, 235, 0.5)",
    "rgba(53, 0, 235, 0.5)",
    "rgba(53, 162, 2, 0.5)",
    "rgba(153, 122, 205, 0.5)",
  ];
  const data = result.map((item, index) => {
    return {
      label: item.name,
      data: item.values,
      borderColor: colors[index],
      backgroundColor: bcolors[index],
    };
  });
  return (
    <Line
      height={300}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              color: colorMode === "dark" ? "white" : "black",
              padding: 30,
            },
          },
          title: {
            color: colorMode === "dark" ? "white" : "black",
            display: true,
            text: "top Products",
          },
        },
        scales: {
          x: {
            ticks: {
              color: colorMode === "dark" ? "white" : "black",
            },
          },
          y: {
            ticks: {
              color: colorMode === "dark" ? "white" : "black",
            },
          },
        },
      }}
      data={{
        labels,
        datasets: data,
      }}
    ></Line>
  );
}
export default LineChart;
