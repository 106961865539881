import React, { useEffect, useState } from "react";
import { Checkbox, Flex } from "@chakra-ui/react";

import QuestionsWithoutInput from "./QuestionsWithoutInput";
import { useSelector, useDispatch } from "react-redux";
import {
  addAnswerToTheOrder,
  addQuestionToTheOrder,
  deleteAnswerFromTheOrder,
} from "../../features/products/ProductSlice";
const CheckBoxGroup = ({ item }) => {
  useEffect(() => {
    dispatch(addQuestionToTheOrder(item.id));
  }, []);
  const { orderAnswers } = useSelector((state) => state.products);
  let defaultChecked = [];
  let arr = item.children.filter((e) => {
    return orderAnswers.includes(e.id);
  });
  if (arr.length > 0) {
    defaultChecked = arr.map((e) => {
      return e.id;
    });
  }
  const [checkedValues, setCheckedValues] = useState(defaultChecked);
  const dispatch = useDispatch();
  const handleChange = (value) => {
    const toggleValue = () => {
      if (checkedValues.includes(value)) {
        dispatch(deleteAnswerFromTheOrder(value));
        return checkedValues.filter((item) => item !== value);
      } else {
        dispatch(addAnswerToTheOrder(value));
        return [...checkedValues, value];
      }
    };

    setCheckedValues((prevCheckedValues) => toggleValue(prevCheckedValues));
  };
  useEffect(() => {}, [checkedValues]);
  const { language } = useSelector((state) => state.lang);

  return (
    <Flex marginLeft={"1rem"} flexDir={"column"}>
      {item?.children?.map((answer, index) => {
        return (
          <>
            <Checkbox
              isChecked={checkedValues.includes(answer.id)}
              onChange={() => handleChange(answer.id)}
            >
              {language === "English" ? answer.value.en : answer.value.de}
            </Checkbox>
            {checkedValues.includes(answer.id) &&
              answer?.children?.map((question, index) => {
                return (
                  <QuestionsWithoutInput
                    item={question}
                  ></QuestionsWithoutInput>
                );
              })}
          </>
        );
      })}
    </Flex>
  );
};

export default CheckBoxGroup;
