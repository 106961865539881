import { Space } from "antd";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
// import { states } from "../../utils/Tools";

export const createInfo = (api, message, text, color) => {
	const key = `open${Date.now()}`;
	const btn = (
		<Space>
			<Button
				mr={"1rem"}
				variant={"outline"}
				colorScheme={color}
				onClick={() => api.destroy()}
			>
				{text.btn1}
			</Button>
			<Link
				to={"/order-info"}
				state={{ id: message.orderId, notificatonId: message.id }}
			>
				<Button colorScheme={color}>{text.btn2}</Button>
			</Link>
		</Space>
	);
	api.open({
		message: text.createTitle,
		description: `${message.username} ${text.createOrder}`,
		btn,
		key,
	});
};
export const updateInfo = (api, message, text, color) => {
	const key = `open${Date.now()}`;
	const btn = (
		<Space>
			<Button
				mr={"1rem"}
				variant={"outline"}
				colorScheme={color}
				onClick={() => api.destroy()}
			>
				{text.btn1}
			</Button>
			<Link
				to={"/order-info"}
				state={{ id: message.orderId, notificatonId: message.id }}
			>
				<Button colorScheme={color}>{text.btn2}</Button>
			</Link>
		</Space>
	);
	api.open({
		message: text.updateTitle,
		description: `${message.username} ${text.updateChanges}`,
		btn,
		key,
	});
};
export const changeStateInfo = (api, message, text, states, color) => {
	const key = `open${Date.now()}`;
	// const linkToUser = (
	// 	<Link to={"/update-user"} state={{ id: message.staffId }}>
	// 		<Button variant="link">{message.username}</Button>
	// 	</Link>
	// );
	const btn = (
		<Space>
			<Button
				mr={"1rem"}
				variant={"outline"}
				colorScheme={color}
				onClick={() => api.destroy()}
			>
				{text.btn1}
			</Button>
			<Link
				to={"/order-info"}
				state={{ id: message.orderId, notificatonId: message.id }}
			>
				<Button colorScheme={color}>{text.btn2}</Button>
			</Link>
		</Space>
	);
	api.open({
		message: text.updateTitle,
		description: (
			<>
				{message.username} {text.updateState} {states[message.state]}
			</>
		),
		btn,
		key,
	});
};

export const changeStateInfoClinic = (api, message, text, states, color) => {
	const key = `open${Date.now()}`;
	// const linkToUser = (
	// 	<Link to={"/update-user"} state={{ id: message.staffId }}>
	// 		<Button variant="link">{message.username}</Button>
	// 	</Link>
	// );
	const btn = (
		<Space>
			<Button
				mr={"1rem"}
				variant={"outline"}
				colorScheme={color}
				onClick={() => api.destroy()}
			>
				{text.btn1}
			</Button>
			<Link to={"/order-info"} state={{ id: message.orderId }}>
				<Button colorScheme={color}>{text.btn2}</Button>
			</Link>
		</Space>
	);
	api.open({
		message: text.updateTitle,
		description: (
			<>
				{text.updatMyState} {states[message.state]}
			</>
		),
		btn,
		key,
	});
};
