import {
  TabPanel,
  Button,
  Select,
  Text,
  Flex,
  Box,
  Card,
  Center,
  CardBody,
} from "@chakra-ui/react";
import { DownOutlined } from "@ant-design/icons";
import { Tree } from "antd";

import React, { useState } from "react";
import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomBox from "../CustomBox";
import { buildAnswersTree, colors } from "../../utils/Tools";
import { updateState } from "../../features/order/orderSlice";
import EditProducts from "./EditProducts";
import Aligner from "./Aligner";
import EditAdminNote from "./EditAdminNote";
import { icons } from "../../utils/Tools";
let values3 = [];
function OrderInfo({ id, isOkToClinic, tree }) {
  const { user } = useSelector((store) => store.user);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  const [editStatus, setEditStatus] = useState(false);
  const [editProducts, setEditProducts] = useState(false);
  const { states } = useSelector((store) => store.lang);
  const {
    updatedData,
    myUpdatedData,
    order: curOrder,
    order: { state },
    order: { product },
    order: { adminNote },
  } = useSelector((store) => store.order);
  const data = useSelector((store) => store.order.order);

  let order = user.role === "Clinic" ? myUpdatedData : updatedData;
  const choiceProducts = (item) => {
    if (values3.includes(item.id)) {
      values3 = values3.filter((id) => id !== item.id);
    } else values3.push(item.id);
  };
  const { controlers, heading, language } = useSelector((store) => store.lang);

  const [STATE, setState] = useState(0);
  let description = `Changes the state from ${states[state]} to ${states[STATE]}`;
  const dispatch = useDispatch();

  return (
    <TabPanel>
      <Text my={"1rem"} fontSize={"xl"}>
        {heading.theProducts}
      </Text>
      {editProducts ? (
        <EditProducts id={id} close={setEditProducts} />
      ) : (
        <Flex justifyContent={"space-between"}>
          <Flex wrap={"wrap"} gap={"2rem"}>
            {product !== undefined &&
              product?.map((item, index) => {
                return (
                  <CustomBox
                    key={index}
                    item={item.product}
                    choice={choiceProducts}
                    isChicked2={true}
                    editable={false}
                  />
                );
              })}
          </Flex>
          {(user.role === "Admin" || isOkToClinic) && (
            <Button
              justifySelf={"end"}
              onClick={() => {
                setEditProducts(true);
              }}
              variant={"link"}
              colorScheme="blue"
            >
              {controlers.edit}
            </Button>
          )}
        </Flex>
      )}
      {/* {console.log(tree)} */}
      <Flex p={"1rem"}>
        {/* {console.log(curOrder.product)}
        {console.log(curOrder.answerIds)} */}
        <Tree
          showLine
          switcherIcon={<DownOutlined />}
          // defaultExpandedKeys={["0-0-0"]}
          // onSelect={onSelect}
          defaultExpandAll={true}
          treeData={buildAnswersTree(
            curOrder.product,
            curOrder.answerIds,
            language
          )}
        />
      </Flex>
      <Divider />
      {editStatus ? (
        <Flex
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Select
            p=".5rem"
            size={"md"}
            width={"fit-content"}
            onChange={(e) => {
              setState(e.target.value);
            }}
          >
            {states.map((item, index) => {
              return (
                <option key={index} value={index}>
                  {item}
                </option>
              );
            })}
          </Select>
          <Button
            colorScheme={btnColorScheme[theme]}
            onClick={() => {
              // console.log(state, STATE);
              if (state !== STATE)
                dispatch(
                  updateState({
                    id: id,
                    clinicId: data.clinic.Id,
                    updateDescription: description,
                    state: STATE,
                  })
                );
              setEditStatus(!editStatus);
            }}
          >
            {controlers.save}
          </Button>
        </Flex>
      ) : (
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          {
            <Card
              paddingX={".2rem"}
              // key={state2}
              bg={colors[state]}
              w={["35%", "30%", "25%"]}
              h={"20%"}
            >
              <CardBody
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Center color={"white"}>{icons[state]}</Center>
                <Center
                  color={"white"}
                  fontWeight={"semibold"}
                  fontSize={"md"}
                  ml="0.5rem"
                >
                  {states[state].toUpperCase()}
                </Center>
              </CardBody>
            </Card>
          }
          {user.role !== "Clinic" && (
            <Button
              onClick={() => {
                setEditStatus(!editStatus);
              }}
              variant={"link"}
              colorScheme="blue"
            >
              {controlers.edit}
            </Button>
          )}
        </Flex>
      )}

      <Divider />
      {curOrder.hasAligner === true ? (
        <>
          <Aligner
            alignerCount1={curOrder.alginerLower}
            alignerCount2={curOrder.alginerUpper}
            role={user.role}
            id={curOrder.id}
          ></Aligner>
          <Divider />
        </>
      ) : (
        ""
      )}
      {order.order.note && order.order.note.length > 0 && (
        <Box
          w={"100%"}
          mb={"1rem"}
          bg={"#ECEEF4"}
          rounded={"md"}
          outline={"1px solid #eee"}
          p={"1rem"}
        >
          <Text
            letterSpacing={"1.2"}
            // bg={"purple.300"}
            // color={"white"}
            p={4}
            fontSize={"1.1rem"}
          >
            {order.order.note}
          </Text>
        </Box>
      )}
      {user.role !== "Clinic" && (
        <Box
          width={"100%"}
          rounded={"md"}
          padding={"1rem"}
          outline={"1px solid #eee"}
          p={"1rem"}
        >
          <EditAdminNote id={data.id} value={adminNote} />
        </Box>
      )}
    </TabPanel>
  );
}

export default OrderInfo;
