import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaUsers } from "react-icons/fa6";
import { TbBuildingHospital, TbPdf } from "react-icons/tb";
import { PiArticle, PiChartLineUpBold } from "react-icons/pi";
// import AllDeliverdOrders from "../pages/accountant/AllDeliverdOrders";
import { FaEdit } from "react-icons/fa";
import { FaChartPie } from "react-icons/fa";
// import { clearFilters } from "../features/allOrders/allOrdersSlice";

const FixedNav = () => {
  const { user, accountant } = useSelector((state) => state.user);
  const { sideBar } = useSelector((store) => store.lang);
  // const paths = []

  let items = [];
  if (user.role === "Clinic") {
    items = [
      {
        label: <Link to="/stats">{sideBar.statistics}</Link>,
        key: "statistics",
        icon: <PiChartLineUpBold />,
      },
      {
        label: <Link to={"all-orders"}>{sideBar.allOrders}</Link>,
        key: "allOrders",
        icon: <PiArticle />,
      },
      {
        label: <Link to="add-order">{sideBar.addOrder}</Link>,
        key: "AddOrder",
        icon: <FaEdit />,
      },
    ];
  } else if (user.role === "Accountant" || accountant) {
    items = [
      {
        key: "2",
        label: <Link to={"invoice-stats"}>{sideBar.statistics}</Link>,
        icon: <FaChartPie />,
      },
      {
        key: "3",
        icon: <PiArticle />,
        label: <Link to={"/all-orders"}>{sideBar.allOrders}</Link>,
      },
    ];
  } else {
    items = [
      {
        label: <Link to="/stats">{sideBar.statistics}</Link>,
        key: "statistics",
        icon: <PiChartLineUpBold />,
      },
      {
        label: (
          <Link to="all-users" state={{ Role: user.role }}>
            {sideBar.staffs}
          </Link>
        ),
        key: "staffs",
        icon: <FaUsers />,
      },
      {
        label: (
          <Link to="all-users" state={{ Role: "clinic" }}>
            {sideBar.clinics}
          </Link>
        ),
        key: "clinics",
        icon: <TbBuildingHospital />,
      },
      {
        label: <Link to={"all-orders"}>{sideBar.allOrders}</Link>,
        key: "/allOrders",
        icon: <PiArticle />,
      },
    ];
  }
  // let initial = "statistics";
  // if (user.role === "Accountant" || accountant) {
  //   initial = "2";
  // }
  const location = useLocation();
  const [current, setCurrent] = useState(null);
  const onClick = (e) => {
    // console.log("click ", e);
    setCurrent(e.key);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    // if (location.pathname !== "all-orders") {
    //   dispatch(clearFilters());
    // }
    if (location.pathname === "/") {
      if (user?.role === "Accountant" || accountant) {
        setCurrent("2");
      } else setCurrent("statistics");
    }
    const invalidPaths = [
      "/add-option",
      "/addAd",
      "/Trash",
      "/order-info",
      "/order-info-acc",
    ];
    if (invalidPaths.includes(location.pathname)) {
      setCurrent(null);
    }
  }, [location.pathname]);

  return (
    <Menu
      // style={{ width: "-moz-fit-content" }}
      //   defaultSelectedKeys={["statistics"]}
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  );
};
export default FixedNav;
