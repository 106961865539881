import {
  Badge,
  Flex,
  SimpleGrid,
  Button,
  useDisclosure,
  AbsoluteCenter,
  Card,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  CardBody,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  GridItem,
  Tooltip,
  Checkbox,
  useColorMode,
} from "@chakra-ui/react";
import HeadingTitle from "./order/HeadingTitle";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteOrder, retriveOrder } from "../features/trash/trashSlice";
import { colors } from "../utils/Tools";
import Loading from "./Loading";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { clearOrder } from "../features/order/orderSlice";

import { icons } from "../utils/Tools";
function Order({
  handler,
  CheckboxIsVisible,
  bcolor,
  order,
  deactivated = false,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let state = order.state;
  let color = colors[state];
  const { user, accountant } = useSelector((store) => store.user);

  let utcTime = new Date(order.createdDate);
  let timezoneOffset = new Date().getTimezoneOffset();

  // Convert UTC time to local time
  let localTime = new Date(utcTime.getTime() - timezoneOffset * 60 * 1000);
  const dateObject = new Date(localTime);
  const dispatch = useDispatch();
  const { states, language } = useSelector((store) => store.lang);
  const { theme, shadeColor, mainColor, btnColorScheme } = useSelector(
    (store) => store.theme
  );
  useEffect(() => {
    dispatch(clearOrder());
  }, []);
  // Format the date as "DD/MM/YYYY"
  const formattedDate = dateObject.toLocaleDateString("en-GB");

  // Format the time as "hh:mm a"
  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const clinicCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    fnameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    snameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    recordCols = user.role === "Clinic" ? 2 : 2,
    statesCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    dateCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    productsCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2];

  const clinicDisplay = user.role === "Clinic" ? "none" : "block",
    fnameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    snameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    recordDisplay = user.role === "Clinic" ? "block" : "block",
    statesDisplay = user.role === "Clinic" ? "block" : "block",
    dateDisplay = user.role === "Clinic" ? "block" : "block",
    productsDisplay = user.role === "Clinic" ? "block" : "block";
  const { isLoading } = useSelector((store) => store.bin);
  const { orderType } = useSelector((store) => store.lang);
  const { buttonsText, diaglogText, orderLabels, filters } = useSelector(
    (store) => store.lang
  );
  const { colorMode } = useColorMode();
  if (deactivated) {
    if (isLoading) return <Loading />;
    return (
      <>
        <SimpleGrid
          columns={user.role === "Clinic" ? 12 : 14}
          alignItems={"left"}
          gap={"1rem"}
          _hover={{
            backgroundColor: shadeColor[theme],

            cursor: "pointer",
          }}
          borderLeftColor={color}
          borderLeftWidth="medium"
          paddingX={".2rem"}
          bg={bcolor}
          rounded={"sm"}
        >
          <GridItem
            colSpan={user.role === "Clinic" ? 10 : [11, 11, 12]}
            onClick={onOpen}
          >
            <SimpleGrid
              columns={user.role === "Clinic" ? 10 : [11, 11, 12]}
              gap={"1rem"}
            >
              {user.role === "Clinic" || (
                <HeadingTitle
                  value={order.clinic.Name}
                  cols={clinicCols}
                  display={clinicDisplay}
                />
              )}
              <HeadingTitle
                value={order.patient.firstName}
                cols={fnameCols}
                display={fnameDisplay}
              />
              <HeadingTitle
                value={order.patient.lastName}
                cols={snameCols}
                display={snameDisplay}
              />
              <HeadingTitle
                value={order.patient.medicalRecordNumber}
                cols={recordCols}
                display={recordDisplay}
              />
              <HeadingTitle
                value={formattedDate + "\n" + formattedTime}
                cols={dateCols}
                display={dateDisplay}
              />

              <HeadingTitle
                state={false}
                display={statesDisplay}
                stateValue={states[state]}
                value={
                  <Flex
                    width={"100%"}
                    // justifyContent={"space-between"}
                    gap={"1rem"}
                  >
                    <Card
                      // p={"1rem"}

                      // alignSelf={"center"}
                      bg={colors[state]}
                      boxShadow={"none"}
                      h={"100%"}
                      w={"fit-content"}
                    >
                      <CardBody>
                        <AbsoluteCenter
                          color={"white"}
                          fontWeight={"bold"}
                          fontSize={"sm"}
                        >
                          {icons.white[state]}
                        </AbsoluteCenter>
                      </CardBody>
                    </Card>
                    <Badge
                      h={"100%"}
                      color={order.isUrgent ? "#821D30" : "#1E5162"}
                      fontSize={"sm"}
                      alignSelf={"center"}
                      bg={order.isUrgent ? "#FAE8E0" : "#D4F1F4"}
                      textTransform={"none"}
                      textAlign="center"
                    >
                      {order.isUrgent ? orderType.urgent : orderType.normal}
                    </Badge>
                  </Flex>
                }
                cols={statesCols}
              />
            </SimpleGrid>
          </GridItem>

          <GridItem colSpan={productsCols} display={productsDisplay}>
            {order.product.length > 1 ? (
              <Menu>
                <MenuButton
                  fontSize={".8rem"}
                  //onClick={handleMenuClick}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {orderLabels.products}
                </MenuButton>
                <MenuList>
                  {order.product.map((item, index) => {
                    return (
                      <MenuItem key={index}>
                        {language === "English"
                          ? item.product.name.en
                          : item.product.name.de}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            ) : (
              <Tooltip
                placement="top"
                label={
                  language === "English"
                    ? order.product[0]?.product?.name.en
                    : order.product[0]?.product?.name.de
                }
              >
                <Box
                  width={"fit-content"}
                  rounded={"md"}
                  cursor={"pointer"}
                  color={"white"}
                  bgColor={mainColor[theme]}
                  paddingX={".4rem"}
                  py={".2rem"}
                  fontSize={".9rem"}
                >
                  {language === "English"
                    ? order.product[0]?.product?.name.en
                    : order.product[0]?.product?.name.de}
                </Box>
              </Tooltip>
            )}
          </GridItem>
        </SimpleGrid>

        <AlertDialog
          motionPreset="slideInBottom"
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>{diaglogText.Discard}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{diaglogText.deleteOr}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                // ref={cancelRef}
                onClick={() => {
                  dispatch(retriveOrder(order.id));
                  onClose();
                }}
              >
                {buttonsText.retrive}
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  dispatch(deleteOrder(order.id));

                  // dispatch(deleteOrder(id));
                  onClose();
                }}
              >
                {buttonsText.delete}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  }
  if (isLoading) return <Loading />;

  return (
    // <>
    <SimpleGrid
      columns={user.role === "Clinic" ? 12 : 16}
      alignItems={"left"}
      w={"100%"}
      gap={"1rem"}
      _hover={{
        backgroundColor: colorMode === "light" ? "#F0F3FF" : "#555",
        cursor: "pointer",
      }}
      borderBottomColor={"#F0F3FF"}
      borderBottomWidth={"2px"}
      borderLeftColor={color}
      borderLeftWidth="medium"
      paddingX={".2rem"}
      // bg={bcolor}
      rounded={"sm"}
    >
      {user.role !== "Clinic" &&
        user.role !== "Accountant" &&
        (CheckboxIsVisible ? (
          <GridItem alignContent={"center"} colSpan={1}>
            <Checkbox
              // key={order.id}
              onChange={(e) => {
                handler(e, order.id, order.clinic.id);
              }}
              defaultChecked
              colorScheme={btnColorScheme[theme]}
            ></Checkbox>
          </GridItem>
        ) : (
          <GridItem colSpan={1}></GridItem>
        ))}

      <GridItem colSpan={user.role === "Clinic" ? 10 : [12, 12, 13]}>
        <Link
          to={
            user.role === "Accountant" || accountant
              ? "/order-info-acc"
              : "/order-info"
          }
          state={{ id: order.id }}
        >
          <SimpleGrid
            columns={user.role === "Clinic" ? 10 : [11, 11, 12]}
            gap={"1rem"}
          >
            {user.role === "Clinic" || (
              <HeadingTitle
                value={order.clinic.Name}
                cols={clinicCols}
                display={clinicDisplay}
              />
            )}
            <HeadingTitle
              value={order.patient.firstName}
              cols={fnameCols}
              display={fnameDisplay}
            />
            <HeadingTitle
              value={order.patient.lastName}
              cols={snameCols}
              display={snameDisplay}
            />
            <HeadingTitle
              value={order.patient.medicalRecordNumber}
              cols={recordCols}
              display={recordDisplay}
            />
            <HeadingTitle
              value={formattedDate + "\n" + formattedTime}
              cols={dateCols}
              display={dateDisplay}
            />

            <HeadingTitle
              state={false}
              stateValue={
                user.role === "Accountant" || accountant
                  ? order.hasInvoice
                    ? filters.hasInvoice
                    : filters.hasNoInvoice
                  : states[state]
              }
              display={statesDisplay}
              value={
                <Flex width={"100%"} gap={"1rem"}>
                  {user.role === "Accountant" || accountant || (
                    <Card boxShadow={"none"} h={"100%"} w={"fit-content"}>
                      <CardBody>
                        <AbsoluteCenter
                          color={colors[state]}
                          fontWeight={"bold"}
                          fontSize={"sm"}
                        >
                          {icons.regular[state]}
                        </AbsoluteCenter>
                      </CardBody>
                    </Card>
                  )}
                  <Badge
                    size={"small"}
                    h={"100%"}
                    color={
                      user.role === "Accountant" || accountant
                        ? order.hasInvoice
                          ? "#1E5162"
                          : "#821D30"
                        : order.isUrgent
                        ? "#821D30"
                        : "#1E5162"
                    }
                    // color={order.isUrgent ? "#821D30" : "#1E5162"}
                    fontSize={"sm"}
                    alignSelf={"center"}
                    // bg={order.isUrgent ? "#FAE8E0" : "#D4F1F4"}
                    bg={
                      user.role === "Accountant" || accountant
                        ? order.hasInvoice
                          ? "#D4F1F4"
                          : "#FAE8E0"
                        : order.isUrgent
                        ? "#FAE8E0"
                        : "#D4F1F4"
                    }
                    textTransform={"none"}
                    textAlign="center"
                  >
                    {user.role === "Accountant" || accountant
                      ? order.hasInvoice
                        ? filters.hasInvoice
                        : filters.hasNoInvoice
                      : order.isUrgent
                      ? orderType.urgent
                      : orderType.normal}
                  </Badge>
                </Flex>
              }
              cols={statesCols}
            />
          </SimpleGrid>
        </Link>
      </GridItem>

      <GridItem
        colSpan={productsCols}
        display={productsDisplay}
        alignContent={"center"}
      >
        {order.product.length > 1 ? (
          <Menu>
            <MenuButton
              fontSize={".8rem"}
              //onClick={handleMenuClick}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              {orderLabels.products}
            </MenuButton>
            <MenuList>
              {order.product.map((item, index) => {
                return (
                  <MenuItem key={index}>
                    {language === "English"
                      ? item.product.name.en
                      : item.product.name.de}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        ) : (
          <Tooltip
            placement="top"
            label={
              language === "English"
                ? order.product[0]?.product?.name.en
                : order.product[0]?.product?.name.de
            }
          >
            <Box
              width={"fit-content"}
              rounded={"md"}
              cursor={"pointer"}
              color={"white"}
              bgColor={mainColor[theme]}
              paddingX={".4rem"}
              py={".2rem"}
              fontSize={".9rem"}
            >
              {language === "English"
                ? order.product[0]?.product?.name.en
                : order.product[0]?.product?.name.de}
            </Box>
          </Tooltip>
        )}
      </GridItem>
    </SimpleGrid>
    /* <Divider></Divider></> */
  );
}

export default Order;
