import { SearchIcon } from "@chakra-ui/icons";
import moment from "moment";
import {
  Input,
  HStack,
  InputGroup,
  InputLeftElement,
  Flex,
  Button,
} from "@chakra-ui/react";

import React, { useState } from "react";
import FilterItem from "./FilterItem";
import { useDispatch, useSelector } from "react-redux";
import { clearFilters, applyFilters } from "../features/trash/trashSlice";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function FiltersOfTrash() {
  const { state, search, sortedAsc } = useSelector((store) => store.bin);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);

  const [searchWord, setSearch] = useState(search);
  const [statu, setStatu] = useState(state);
  const [selectedRange, setSelectedRange] = useState([]);
  const handler = (e) => {
    setStatu(e.target.value);
  };
  const [sorted, setSorted] = useState(sortedAsc);
  const handleChange = (dates, dateStrings) => {
    // Format the date strings to "MM/DD/YYYY" and update state
    setSelectedRange(
      dateStrings.map((date) => moment(date).format("MM/DD/YYYY"))
    );
  };
  const { buttonsText } = useSelector((store) => store.lang);
  const { role } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  return (
    <Flex
      direction={"column"}
      p={"1rem"}
      // boxShadow="sm"
      border={"1px solid"}
      borderColor={"gray.100"}
      gap="2rem"
    >
      <HStack>
        <InputGroup>
          <InputLeftElement>
            <SearchIcon></SearchIcon>
          </InputLeftElement>
          <Input
            value={searchWord}
            type={"search"}
            bg="ButtonFace"
            name="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></Input>
        </InputGroup>
        <FilterItem value={statu} handler={handler} />
      </HStack>

      <RangePicker
        value={selectedRange.map((date) => moment(date, "MM/DD/YYYY"))}
        format={"MM/DD/YYYY"}
        onChange={handleChange}
      />

      {/* <DatePicker /> */}
      <HStack alignSelf={"end"} gap={"1rem"}>
        <Button
          alignSelf={"end"}
          width={"fit-content"}
          colorScheme={btnColorScheme[theme]}
          paddingX={"1rem"}
          py={".1rem"}
          variant={"outline"}
          onClick={() => {
            dispatch(clearFilters());
          }}
        >
          {buttonsText.clear}
        </Button>
        <Button
          width={"fit-content"}
          colorScheme={btnColorScheme[theme]}
          paddingX={"1rem"}
          py={".1rem"}
          onClick={() => {
            const [start, end] = selectedRange == [] ? ["", ""] : selectedRange;
            dispatch(
              applyFilters({
                sortedAsc: sorted,
                state: statu,
                search: searchWord,
                startDate: start === "" ? "all" : start,
                endDate: end === "" ? "all" : end,
              })
            );
          }}
        >
          {buttonsText.filter}
        </Button>
      </HStack>
    </Flex>
  );
}

export default FiltersOfTrash;
