import React from "react";
import { Progress, Space } from "antd";
import { useSelector } from "react-redux";
import { AbsoluteCenter, Flex, Text } from "@chakra-ui/react";

function UploadProgress() {
	const { loaded, total } = useSelector((store) => store.order);
	const { progress } = useSelector((store) => store.lang);
	return (
		<Flex
			h={"100%"}
			alignContent={"center"}
			w={"100%"}
			justifyContent={"center"}
			alignItems={"center"}
		>
			{/* <AbsoluteCenter> */}
			<Space wrap>
				<Progress
					type="circle"
					percent={Math.floor((loaded / total) * 100)}
					format={() => {
						if (Math.floor((loaded / total) * 100) < 100)
							return Math.floor((loaded / total) * 100) + "%";
						else
							return (
								<Text fontSize={"1.1rem"}>
									{`${progress.saving}`}
								</Text>
							);
					}}
				/>

				<Text>{progress.loading} : </Text>

				<Progress
					type="circle"
					percent={Math.floor((loaded / total) * 100)}
					format={() => `${loaded}MB`}
				/>
				<Text>{progress.from} : </Text>
				<Progress
					type="circle"
					status="success"
					// strokeColor={"green"}
					percent={100}
					format={() => "" + total + "MB"}
				/>
			</Space>
			{/* </AbsoluteCenter> */}
		</Flex>
	);
}

export default UploadProgress;
