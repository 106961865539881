import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createdSuccessfuly } from "../../features/order/orderSlice";
import { updatedtedSuccessfuly } from "../../features/products/ProductSlice";

const Success = () => {
  const { error, buttonsText } = useSelector((store) => store.lang);
  const dispatch = useDispatch();
  dispatch(createdSuccessfuly(false));
  dispatch(updatedtedSuccessfuly(false));
  return (
    <Result
      status="success"
      title={error.success}
      // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
      extra={[
        <Link to={"/"}>
          <Button type="primary" key="console">
            {buttonsText.backButton}
          </Button>
        </Link>,
        // <Button key="buy">Buy Again</Button>,
      ]}
    />
  );
};
export default Success;
