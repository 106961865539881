import { Text, Flex } from "@chakra-ui/react";
import React from "react";
import img from "../../assets/images/1.svg";

function Message({ userName, sender, message, time, icon }) {
  // console.log(icon);
  return (
    <Text
      my={".5rem"}
      p={"1rem"}
      //rounded={"md"}

      borderRadius={sender ? "1rem 1rem 0 1rem" : "1rem 1rem 1rem 0"}
      // minW={"10%"}
      //bgImg={"img"}
      bgSize={"cover"}
      width={"fit-content"}
      color={sender ? "black" : "black"}
      fontSize={"1rem"}
      maxW={"80%"}
      alignSelf={sender ? "end" : "start"}
      bgColor={sender ? "#eadef8" : "white"}
      mx={"1rem"}
      boxShadow={"sm"}
    >
      <Text
        letterSpacing={"1.1"}
        fontSize={".9rem"}
        fontWeight={"semibold"}
        color={sender ? "pink.700" : "yellow.700"}
      >
        {userName}
      </Text>
      {message}

      {/* <Text
				mt={".2rem"}
				color={"#40484D"}
				fontSize={".6rem"}
				textAlign={"right"}
				w={"100%"}
			>
				{time}
				<IoCheckmarkOutline />
			</Text> */}
      <Flex align="center" justify="flex-end">
        <Text
          mt=".2rem"
          color="#40484D"
          fontSize=".6rem"
          textAlign="right"
          w="100%"
        >
          {time}
        </Text>
        {icon}
      </Flex>
    </Text>
  );
}

export default Message;
