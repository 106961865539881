import { Card, Flex, Select } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceStats } from "../../features/statistics/statisticsSlice";
import Bars from "../../components/Bars";
import LineChart from "./LineCharts";
import MonthCard from "./MonthCard";
function AccountantStats() {
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const years = Array.from(
    { length: currentYear - 2023 },
    (_, index) => 2024 + index
  );

  // console.log(currentMonthNumber); // Outputs 9 for September

  const { invoice } = useSelector((store) => store.statistics);
  const contributionData =
    invoice === undefined ? {} : Object.values(invoice).map((val) => val);
  useEffect(() => {
    dispatch(getInvoiceStats(year));
  }, [year]);
  return (
    <Flex
      marginTop={"1rem"}
      flexDir={"column"}
      width={"100%"}
      justifyContent={"center"}
    >
      <MonthCard contributionData={contributionData} />
      <Select
        marginY={"1rem"}
        value={year}
        onChange={(e) => {
          if (e.target.value !== year) setYear(e.target.value);
        }}
        w={"30%"}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
      <Flex gap={"1rem"}>
        <Card w={"48%"}>
          <Bars contributionData={contributionData} isInvoice={true} />
        </Card>
        <Card w={"48%"}>
          <LineChart contributionData={contributionData} />
        </Card>
      </Flex>
    </Flex>
  );
}

export default AccountantStats;
