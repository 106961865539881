import { message } from "antd";
import { logOut } from "../features/user/UserSlice";
export const getTheError = function (error, thunkAPI) {
  if (error.response.status === 401 || error.response.status === 403) {
    // removeUserFromLocalStorage();
    // message.error(thunkAPI.getState().lang.error.sessionExpired);
    // thunkAPI.getState().user.user = null;
    thunkAPI.dispatch(logOut());
    return thunkAPI.rejectWithValue(
      thunkAPI.getState().lang.error.sessionExpired
    );

    // return thunkAPI.rejectWithValue("logOut");
    // console.log(error);
  }
  if (
    !error.response ||
    !error.response.data ||
    !error.response.data.message ||
    error.response.data.code === "A7DF"
  ) {
    return thunkAPI.rejectWithValue(thunkAPI.getState().lang.error.serverError);
  }
  return thunkAPI.rejectWithValue(error.response.data.message);
};
