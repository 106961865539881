import React, { useEffect, useState } from "react";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Button, Input, Menu, Checkbox } from "antd";
import { useColorMode } from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  HStack,
  InputGroup,
  InputLeftElement,
  Flex,
  AbsoluteCenter,
} from "@chakra-ui/react";

import { FaSortNumericDown, FaSortNumericUp } from "react-icons/fa";
import FilterItem from "../../components/FilterItem";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilters,
  handleChange,
  applyFilters,
} from "../../features/allOrders/allOrdersSlice";
import { DatePicker } from "antd";
import FilterPriority from "../../components/FilterPriority";
import Search from "antd/es/input/Search";
import { Search2Icon } from "@chakra-ui/icons";
import { MdDateRange } from "react-icons/md";
import { AiFillControl } from "react-icons/ai";
const { RangePicker } = DatePicker;

const AccountantFilters = () => {
  const {
    state,
    search,
    sortedAsc,
    productId,
    priority: pri,
  } = useSelector((store) => store.orders);
  const dispatch = useDispatch();
  const [searchWord, setSearch] = useState(search);
  const [statu, setStatu] = useState(state);
  const [priority, setPriority] = useState(pri);
  const [selectedRange, setSelectedRange] = useState([]);
  const handler = (e) => {
    setStatu(e.target.value);
  };
  const { products } = useSelector((store) => store.products);
  const [productfilter, setProduct] = useState(productId);
  const handlerProduct = (e) => {
    setProduct(e.target.value);
  };
  const handlePriority = (e) => {
    setPriority(e.target.value);
  };
  const handleChange = (dates, dateStrings) => {
    // Format the date strings to "MM/DD/YYYY" and update state
    setSelectedRange(
      dateStrings?.map((date) => dayjs(date).format("MM/DD/YYYY"))
    );
  };
  const [sorted, setSorted] = useState(sortedAsc);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  const { buttonsText } = useSelector((store) => store.lang);

  const { colorMode } = useColorMode();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const items = [
    {
      key: "1",
      icon: <Search2Icon />,
      label: collapsed ? (
        ""
      ) : (
        <Search
          onKeyDown={(e) => {
            if (e.key === "Enter" && searchWord.trim() !== "") {
              const [start, end] = selectedRange;
              dispatch(
                applyFilters({
                  state: statu,
                  search: searchWord,
                  startDate: start === "" ? "all" : start,
                  endDate: end === "" ? "all" : end,
                  sortedAsc: sorted,
                  productId: productfilter,
                  priority: priority,
                })
              );
            }
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      ),
    },
    {
      key: "2",
      icon: <MdDateRange />,
      label: collapsed ? (
        ""
      ) : (
        <RangePicker
          value={selectedRange?.map((date) => dayjs(date, "MM/DD/YYYY"))}
          format={"MM/DD/YYYY"}
          onChange={handleChange}
        />
      ),
    },
    {
      key: "3",
      label: <Checkboxes />,
    },
    {
      key: "sub1",
      label: "",
      icon: <AiFillControl />,
      children: [
        {
          key: "5",
          label: (
            <Button
              style={{ width: "100%" }}
              size="large"
              danger
              ghost
              variant={"outline"}
              onClick={() => {
                dispatch(clearFilters());
              }}
            >
              {buttonsText.clear}
            </Button>
          ),
        },
        {
          key: "6",
          label: (
            <Button
              style={{ width: "100%" }}
              size="large"
              onClick={() => {
                const [start, end] = selectedRange;
                dispatch(
                  applyFilters({
                    state: statu,
                    search: searchWord,
                    startDate: start === "" ? "all" : start,
                    endDate: end === "" ? "all" : end,
                    sortedAsc: sorted,
                    productId: productfilter,
                    priority: priority,
                  })
                );
              }}
            >
              {buttonsText.filter}
            </Button>
          ),
        },
      ],
    },
  ];
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode={collapsed ? "horizontal" : "inline"}
        theme={colorMode === "dark" ? "dark" : "light"}
        inlineCollapsed={collapsed}
        items={items}
      />
    </div>
  );
};
export default AccountantFilters;
const options = [
  {
    label: "isPaid",
    value: "Apple",
  },
  {
    label: "hasInvoice",
    value: "Pear",
  },
];
const Checkboxes = () => {
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  return <Checkbox.Group options={options} onChange={onChange} />;
};
