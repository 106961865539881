import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const ServerError = () => {
	const { error, buttonsText } = useSelector((store) => store.lang);
	return (
		<Result
			status="500"
			title="500"
			subTitle={error.serverError}
			extra={
				<Link to={"/"}>
					<Button type="primary">{buttonsText.backButton}</Button>
				</Link>
			}
		/>
	);
};
export default ServerError;
