import React from "react";
import { useSelector } from "react-redux";
import { Select } from "@chakra-ui/react";
function FilterPriority({ value, handler }) {
	const { filters } = useSelector((store) => store.lang);
	return (
		<Select value={value} w={"100%"} onChange={handler}>
			<option value={"all"}>{filters.priority}</option>

			<option value={true}>{filters.urgent}</option>
			<option value={false}>{filters.normal}</option>
		</Select>
	);
}

export default FilterPriority;
