// import React, { useState } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

// const NewTextEditor = ({ sendMessage, submit }) => {
//   const [message, setMessage] = useState("");

//   const changeHandler = (value) => {
//     setMessage(value);
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && !e.shiftKey) {
//       e.preventDefault();
//       //   console.log(message);
//       if (message.trim() !== "") {
//         sendMessage(message);
//         // sendMessage(message);
//         // submit(message);
//         // console.log(message);
//         // setMessage(""); // Clear input after sending
//       }
//     }
//   };

//   return (
//     <ReactQuill
//       value={message}
//       onChange={changeHandler}
//       placeholder=""
//       onKeyDown={handleKeyDown}
//       modules={{
//         toolbar: [
//           [{ size: ["small", false, "large", "huge"] }], // Font size options
//           [{ list: "ordered" }, { list: "bullet" }],
//           ["bold", "italic", "underline"],
//           ["link", "video"], // Include image button, works like link insertion
//           ["clean"], // remove formatting button
//         ],
//       }}
//       formats={["size", "list", "bold", "italic", "underline", "link", "video"]}
//       style={{
//         borderRadius: ".6rem",
//         padding: "1rem",
//         background: "white",
//         width: "100%",
//       }}
//     />
//   );
// };

// export default NewTextEditor;

import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Popconfirm } from "antd";
import { Input, IconButton, InputGroup } from "@chakra-ui/react";
import { FaImage } from "react-icons/fa6";
import { MdPreview } from "react-icons/md";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import NewMessage from "../components/chat/NewMessage";
const NewTextEditor = ({ sendMessage }) => {
  const quillRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageUrl, setImageUrl] = useState(""); // Store the entered image URL
  const [message, setMessage] = useState("");

  const changeHandler = (value) => {
    setMessage(value);
    sendMessage(value);
  };
  // Function to insert the image
  const handleImage = () => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();

    if (imageUrl) {
      editor.insertEmbed(range?.index, "image", imageUrl); // Quill will embed this as a video
    }
  };
  const confirm = (e) => {
    handleImage();
    setImageUrl("");
  };
  const cancel = (e) => {
    setImageUrl("");
  };

  // Quill toolbar options with custom image handler

  const urlHandler = (e) => {
    // console.log(e);
    setImageUrl(e?.target?.value);
  };
  return (
    <InputGroup w={["100%", "100%", "80%", "60%"]} position="relative">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody minH={"25vh"}>
            {<NewMessage rawContent={message} />}
          </ModalBody>
        </ModalContent>
      </Modal>
      <ReactQuill
        onChange={changeHandler}
        ref={quillRef}
        modules={{
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }], // Font size options
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            ["clean"], // remove formatting button
          ],
        }}
        formats={[
          "size",
          "list",
          "bold",
          "italic",
          "underline",
          "link",
          "image",
        ]}
        style={{
          borderRadius: ".6rem",
          height: "fit-content",
          // maxHeight: "6rem",
          background: "white",
          marginBottom: "2rem",
          width: "100%",
          border: "1px solid #ccc", // Added border here
        }}
      />
      <Popconfirm
        title="enter image url"
        description=<Input value={imageUrl} onChange={urlHandler} />
        onConfirm={confirm}
        onCancel={cancel}
        okText="save"
        cancelText="cancle"
      >
        <IconButton
          position="absolute"
          top=".1rem"
          right="1rem"
          variant={"unstyled"}
          icon={<FaImage />}
        />
      </Popconfirm>
      <IconButton
        variant={"ghost"}
        icon={<MdPreview />}
        pos={"absolute"}
        bottom={"2rem"}
        colorScheme="purple"
        right={"1"}
        onClick={onOpen}
      />
      {/* Custom input field for entering image URL */}
    </InputGroup>
  );
};

export default NewTextEditor;
