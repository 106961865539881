import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Unauthorized = () => {
	const { error, buttonsText } = useSelector((store) => store.lang);
	return (
		<Result
			status="403"
			title="403"
			subTitle={error.forbidden}
			extra={
				<Link to={"/"}>
					<Button type="primary">{buttonsText.backButton}</Button>
				</Link>
			}
		/>
	);
};
export default Unauthorized;
