import React from "react";
import { Flex, Text, useColorMode } from "@chakra-ui/react";
function Footer() {
  const { colorMode } = useColorMode();
  return (
    <footer>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        height={"4rem"}
        mb={"1rem"}
        rounded={"md"}
        bg={colorMode === "dark" ? "#424242" : "white"}
        paddingX={4}
      >
        <Text fontSize={"small"} fontWeight={"semi-bold"}>
          © 2022 by Solutions 4 Medical Practices GmbH
        </Text>
      </Flex>
    </footer>
  );
}

export default Footer;
