import React, { useState } from "react";
import { Button, InputNumber, Space } from "antd";
import { Divider, Statistic } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateAligner } from "../../features/order/orderSlice";
import { Flex, Text } from "@chakra-ui/react";
function Aligner({ role, alignerCount1, alignerCount2, id }) {
  const { buttonsText, alignerLabels } = useSelector((store) => store.lang);
  const [value1, setValue1] = useState(alignerCount1);
  const [value2, setValue2] = useState(alignerCount2);
  // console.log(value1, alignerCount1);
  const dispatch = useDispatch();
  if (role !== "Admin" && role !== "Staff") {
    return (
      <Flex gap={"4rem"} width={"100%"}>
        <Statistic title={alignerLabels.lowerCount} value={value1} />
        <Statistic title={alignerLabels.upperCount} value={value2} />
      </Flex>
    );
  }

  return (
    <>
      <Flex flexDir="column" gap={"1rem"}>
        <Flex gap={"2rem"}>
          <Text> {alignerLabels.upperCount} : </Text>
          <InputNumber min={0} max={100} value={value2} onChange={setValue2} />

          <Button
            type="primary"
            ghost
            danger
            onClick={() => {
              setValue2(alignerCount2);
            }}
          >
            Reset
          </Button>
        </Flex>
        <Flex gap={"2rem"}>
          <Text> {alignerLabels.lowerCount} : </Text>
          <InputNumber min={0} max={100} value={value1} onChange={setValue1} />

          <Button
            type="primary"
            ghost
            danger
            onClick={() => {
              setValue1(alignerCount1);
            }}
          >
            Reset
          </Button>
        </Flex>

        <Button
          style={{ width: "fit-content", alignSelf: "end" }}
          type="primary"
          ghost
          onClick={() => {
            if (value1 + value2 !== 0)
              dispatch(
                updateAligner({ id: id, count1: value1, count2: value2 })
              );
          }}
        >
          {buttonsText.update}
        </Button>
      </Flex>
    </>
  );
}

export default Aligner;
