import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	theme: 0, //["default", "ramadan"],0=>default, 1=>ramadan and so on
	btnColorScheme: ["purple", "yellow"],
	mainColor: ["purple.400", "yellow.500"],
	shadeColor: ["purple.400", "yellow.500"],
};

const themesSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		changeTheme: (state, { payload }) => {
			state.theme = payload;
		},
	},
});

export default themesSlice.reducer;
export const { changeTheme } = themesSlice.actions;
