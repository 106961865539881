import React, { useEffect, useState } from "react";

import { Button, ConfigProvider, Input, Menu } from "antd";
import { useColorMode, Select } from "@chakra-ui/react";
import dayjs from "dayjs";

import { FaSort } from "react-icons/fa";
// import FilterItem from "../../components/FilterItem";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilters,
  applyFilters,
} from "../features/allOrders/allOrdersSlice";
import { DatePicker } from "antd";
import FilterPriority from "./FilterPriority";
import FilterProducts from "./FilterProducts";
import { TbStatusChange } from "react-icons/tb";
import { Search2Icon } from "@chakra-ui/icons";
import { MdClear, MdDateRange, MdLowPriority } from "react-icons/md";
import FilterItem from "./FilterItem";
import { FaFilter } from "react-icons/fa6";
import { PiListChecksBold } from "react-icons/pi";
import { GiPayMoney } from "react-icons/gi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { LuListChecks } from "react-icons/lu";
const { RangePicker } = DatePicker;

const NewFilter = ({ collapsed2, openLayout }) => {
  const {
    state,
    search,
    sortedAsc,
    productId,
    priority: pri,
    isPaid,
    hasOrderInvoice,
  } = useSelector((store) => store.orders);
  const dispatch = useDispatch();
  const [searchWord, setSearch] = useState(search);
  const [statu, setStatu] = useState(state);
  const [priority, setPriority] = useState(pri);
  const [paid, setPaid] = useState(isPaid);
  const [hasInvoice, setHasInvoice] = useState(hasOrderInvoice);
  const [selectedRange, setSelectedRange] = useState([]);
  const { user, accountant } = useSelector((state) => state.user);
  const handler = (e) => {
    setStatu(e.target.value);
  };
  const paidHandler = (e) => {
    setPaid(e.target.value);
  };
  const hasInvoiceHandler = (e) => {
    setHasInvoice(e.target.value);
  };
  const { products } = useSelector((store) => store.products);
  const [productfilter, setProduct] = useState(productId);
  const handlerProduct = (e) => {
    setProduct(e.target.value);
  };
  const handlePriority = (e) => {
    setPriority(e.target.value);
  };
  const handleChange = (dates, dateStrings) => {
    // Format the date strings to "MM/DD/YYYY" and update state
    setSelectedRange(
      dateStrings?.map((date) => dayjs(date).format("MM/DD/YYYY"))
    );
  };
  const [sorted, setSorted] = useState(sortedAsc);
  const { buttonsText, filters, allOrders } = useSelector(
    (store) => store.lang
  );
  const { colorMode } = useColorMode();
  // const [collapsed, setCollapsed] = useState(collapsed2);
  // const toggleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };
  const items = [
    {
      key: "1",
      icon: <Search2Icon />,
      label:
        collapsed2 === true ? (
          ""
        ) : (
          <Input
            style={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && searchWord.trim() !== "") {
                const [start, end] = selectedRange;
                dispatch(
                  applyFilters({
                    state: statu,
                    search: searchWord,
                    startDate: start === "" ? "all" : start,
                    endDate: end === "" ? "all" : end,
                    sortedAsc: sorted,
                    productId: productfilter,
                    priority: priority,
                    isPaid: paid,
                    hasOrderInvoice: hasInvoice,
                  })
                );
              }
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        ),
    },
    {
      key: "2",
      icon: <MdDateRange />,
      label:
        collapsed2 === true ? (
          allOrders.date
        ) : (
          <RangePicker
            value={selectedRange?.map((date) => dayjs(date, "MM/DD/YYYY"))}
            format={"MM/DD/YYYY"}
            onChange={handleChange}
          />
        ),
    },
    user.role !== "Accountant" && !accountant
      ? ""
      : {
          key: "2.1",
          label:
            collapsed2 === true ? (
              filters.isPaid
            ) : (
              <FilterInvoice value={paid} handler={paidHandler} />
            ),
          icon: (
            <GiPayMoney
              onClick={() => {
                openLayout();
                // console.log("hi");
              }}
            />
          ),
        },
    user.role !== "Accountant" && !accountant
      ? ""
      : {
          key: "2.2",
          icon: (
            <FaFileInvoiceDollar
              onClick={() => {
                openLayout();
                // console.log("hi");
              }}
            />
          ),
          label:
            collapsed2 === true ? (
              filters.hasInvoice
            ) : (
              <FilterInvoice2 value={hasInvoice} handler={hasInvoiceHandler} />
            ),
        },
    {
      key: "3",
      icon: <PiListChecksBold />,
      label:
        collapsed2 === true ? (
          allOrders.producs
        ) : (
          <FilterProducts
            handler={handlerProduct}
            value={productfilter}
            products={products}
          />
        ),
    },
    {
      key: "4",
      icon: <MdLowPriority />,
      label:
        collapsed2 === true ? (
          filters.priority
        ) : (
          <FilterPriority handler={handlePriority} value={priority} />
        ),
    },
    user.role === "Accountant" || accountant
      ? ""
      : {
          // disabled: true,
          key: "8",
          label:
            collapsed2 === true ? (
              allOrders.state
            ) : (
              <FilterItem value={statu} handler={handler} />
            ),
          icon: (
            <TbStatusChange
              onClick={() => {
                openLayout();
                // console.log("hi");
              }}
            />
          ),
        },
    {
      icon: <FaSort />,
      key: "5",
      label:
        collapsed2 === true ? (
          ""
        ) : (
          <Button style={{ width: "100%" }} onClick={() => setSorted(!sorted)}>
            {sorted ? buttonsText.oldest : buttonsText.newest}
          </Button>
        ),
    },

    {
      icon: <MdClear />,
      key: "6",
      label:
        collapsed2 === true ? (
          buttonsText.clear
        ) : (
          <Button
            style={{ width: "100%" }}
            size="large"
            danger
            ghost
            variant={"outline"}
            onClick={() => {
              dispatch(clearFilters());
            }}
          >
            {buttonsText.clear}
          </Button>
        ),
    },

    {
      icon: <FaFilter />,
      key: "7",
      label:
        collapsed2 === true ? (
          buttonsText.filter
        ) : (
          <Button
            style={{ width: "100%" }}
            size="large"
            onClick={() => {
              const [start, end] = selectedRange;
              dispatch(
                applyFilters({
                  state: statu,
                  search: searchWord,
                  startDate: start === "" ? "all" : start,
                  endDate: end === "" ? "all" : end,
                  sortedAsc: sorted,
                  productId: productfilter,
                  priority: priority,
                  isPaid: paid,
                  hasOrderInvoice: hasInvoice,
                })
              );
            }}
          >
            {buttonsText.filter}
          </Button>
        ),
    },
  ];
  return (
    <div
      style={{
        width: "100%",
        top: "10vh",
        position: "-webkit-sticky",
        position: "sticky",
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemHeight: 60,
            },
          },
        }}
      >
        <Menu
          style={{
            display: "flex",
            flexDirection: "column",
            // rowGap: "1rem",
          }}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode={"inline"}
          theme={colorMode === "dark" ? "dark" : "light"}
          inlineCollapsed={collapsed2}
          items={items}
        />
      </ConfigProvider>
    </div>
  );
};

export default NewFilter;

function FilterInvoice({ value, handler }) {
  const { buttonsText, filters } = useSelector((store) => store.lang);
  return (
    <Select value={value} w={"100%"} onChange={handler}>
      <option value={"all"}>{filters.isPaid}</option>

      <option value={true}>{buttonsText.yes}</option>
      <option value={false}>{buttonsText.No}</option>
    </Select>
  );
}
function FilterInvoice2({ value, handler }) {
  const { buttonsText, filters } = useSelector((store) => store.lang);
  return (
    <Select value={value} w={"100%"} onChange={handler}>
      <option value={"all"}>{filters.hasInvoice}</option>

      <option value={true}>{buttonsText.yes}</option>
      <option value={false}>{buttonsText.No}</option>
    </Select>
  );
}
