import FadeLoader from "react-spinners/FadeLoader";

const override = {
  display: "block",
  margin: "20vh auto",
  //   borderColor: "cyan",
};

function Loading() {
  return (
    <div className="sweet-loading">
      <FadeLoader
        color={"#4A249D"}
        // loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Loading;
