import React from "react";
import { Progress, Space } from "antd";
import { useSelector } from "react-redux";
import { AbsoluteCenter, Flex, Text } from "@chakra-ui/react";

function DownloadProgress() {
	const { loaded } = useSelector((store) => store.order);
	const { progress } = useSelector((store) => store.lang);
	return (
		<Flex
			h={"100%"}
			alignContent={"center"}
			w={"100%"}
			justifyContent={"center"}
			alignItems={"center"}
		>
			{/* <AbsoluteCenter> */}
			<Space wrap>
				<Text>{progress.loading} : </Text>

				<Progress type="circle" format={() => `${loaded}MB`} />
			</Space>
			{/* </AbsoluteCenter> */}
		</Flex>
	);
}

export default DownloadProgress;
