import { Grid, GridItem, Text, useColorMode } from "@chakra-ui/react";
import React from "react";

function NoneEditableBox({ title, value }) {
	const { colorMode } = useColorMode();
	return (
		<Grid
			templateColumns={"repeat(7,1fr)"}
			p={"1rem"}
			borderBottom={"2px solid"}
			borderColor="gray.100"
		>
			<GridItem colSpan={2}>
				<Text
					fontSize={"1rem"}
					color={colorMode === "dark" ? "gray.100" : "gray.500"}
					fontWeight={"semibold"}
				>
					{title} :
				</Text>
			</GridItem>
			<GridItem colSpan={5}>
				<Text
					fontSize={"1rem"}
					color={colorMode === "dark" ? "gray.100" : "gray.500"}
					fontWeight={"semibold"}
				>
					{value}
				</Text>
			</GridItem>
		</Grid>
	);
}

export default NoneEditableBox;
