import { SimpleGrid, GridItem, Text, useColorMode } from "@chakra-ui/react";
import React from "react";

function NoneEditableOrderBox({ title, value }) {
	const { colorMode } = useColorMode();
	return (
		<SimpleGrid
			columns={10}
			p={"1rem"}
			borderBottom={"2px solid"}
			borderColor="gray.100"
		>
			<GridItem colSpan={4}>
				<Text
					fontSize={"1rem"}
					color={colorMode === "dark" ? "gray.100" : "gray.500"}
					fontWeight={"semibold"}
				>
					{title} :
				</Text>
			</GridItem>
			<GridItem colSpan={6}>
				<Text
					fontSize={"1rem"}
					color={colorMode === "dark" ? "gray.100" : "gray.500"}
					fontWeight={"semibold"}
				>
					{value}
				</Text>
			</GridItem>
		</SimpleGrid>
	);
}

export default NoneEditableOrderBox;
