import React from "react";
import DOMPurify from "dompurify";
function NewMessage({ rawContent }) {
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    if (node.tagName === "A") {
      // Ensure the link opens in a new tab and is secure
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
      node.setAttribute("style", "color:#87A2FF;font-weight:bold");

      // Check if the href doesn't start with http:// or https://
      const href = node.getAttribute("href");
      if (href && !href.startsWith("http://") && !href.startsWith("https://")) {
        node.setAttribute("href", `http://${href}`);
      }
    }
  });

  const sanitizedContent = DOMPurify.sanitize(rawContent);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
}

export default NewMessage;
