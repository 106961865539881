import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Search2Icon } from "@chakra-ui/icons";
import { applyFilters } from "../features/user/AllClinicsSlice";
import { applyFilters as applyFilters2 } from "../features/user/AllStaffSlice";
import AllClinics from "./AllClinics";
import AllStaff from "./AllStaff";

import { logOut } from "../features/user/UserSlice";
let value = "";
function AllUsers() {
  const { user } = useSelector((store) => store.user);

  const searchinput = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);
  const location = useLocation();
  let Role = location.state?.Role;
  const { userText } = useSelector((store) => store.lang);

  useEffect(() => {
    if (searchinput && searchinput.current) searchinput.current.value = "";
    if (Role === "clinic") dispatch(applyFilters({ search: "" }));
    else if (Role !== undefined) dispatch(applyFilters2({ search: "" }));
  }, [Role]);
  if (Role === undefined || Role === null || user.role === "Clinic")
    return <Navigate to={"/404"} />;
  return (
    <>
      <InputGroup mb={"2rem"}>
        <Input placeholder={userText.searchForUser} ref={searchinput} />
        <InputRightElement>
          <IconButton
            icon={<Search2Icon />}
            onClick={() => {
              value = searchinput.current.value;
              if (Role === "clinic") dispatch(applyFilters({ search: value }));
              else dispatch(applyFilters2({ search: value }));
            }}
          ></IconButton>
        </InputRightElement>
      </InputGroup>

      {Role === "clinic" ? <AllClinics /> : <AllStaff />}
    </>
  );
}

export default AllUsers;
