import React, { useState, useEffect } from "react";
import {
	updateMedicalRecord,
	updateOrder,
} from "../../features/order/orderSlice";
import {
	Text,
	SimpleGrid,
	IconButton,
	Box,
	Flex,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	useDisclosure,
	Button,
	Input,
	GridItem,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
	castMyUpdatedData,
	castUpdatedData,
} from "../../features/order/orderSlice";
function EditPatienId({ id }) {
	const dispatch = useDispatch();
	const { user } = useSelector((store) => store.user);
	useEffect(() => {
		dispatch(castMyUpdatedData());
		dispatch(castUpdatedData());
	}, [dispatch]);
	const { theme, btnColorScheme } = useSelector((store) => store.theme);
	let { updatedData, myUpdatedData } = useSelector((store) => store.order);
	let order =
		user.role === "Clinic"
			? Object.assign({}, myUpdatedData)
			: Object.assign({}, updatedData);
	const [value, setValue] = useState(
		order.order.patientInfo.medicalRecordNumber
	);

	const submitHandler = () => {
		if (value !== order.order.patientInfo.medicalRecordNumber) {
			const data = { id: id, order: Object.assign({}, order) };
			// Make a deep copy of the order object and its nested properties
			data.order = JSON.parse(JSON.stringify(order));

			// Update the patient MedicalRecordNumber in the copied object
			data.order.order.patientInfo.medicalRecordNumber = value;

			// Optionally, update the history or other properties if needed
			if (user.role !== "Clinic")
				data.order.history.updateDescription = note;

			dispatch(updateOrder(data));
			dispatch(
				updateMedicalRecord({ MedicalId: value, description: note })
			);
		}
		onClose();
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef();
	const note = `Change the patient Medical Record from ${order.order.patientInfo.medicalRecordNumber} To ${value}`;
	const { orderLabels, buttonsText, diaglogText } = useSelector(
		(store) => store.lang
	);
	return (
		<Box rounded={"md"}>
			<SimpleGrid
				columns={10}
				p={"1rem"}
				alignItems={"center"}
				justifyContent={"space-between"}
				boxShadow={"sm"}
			>
				<GridItem colSpan={4}>
					<Text fontWeight={"semibold"} fontSize={"1rem"}>
						{orderLabels.id}
					</Text>
				</GridItem>
				<GridItem colSpan={4}>
					<Text fontWeight={"semibold"} fontSize={"1rem"}>
						{order.order.patientInfo.medicalRecordNumber}
					</Text>
				</GridItem>

				<GridItem colSpan={2} justifySelf={"end"}>
					<IconButton
						onClick={onOpen}
						// transform="scale(.8)"
						fontSize={"md"}
						variant={"solid"}
						rounded={"md"}
						icon={<EditIcon />}
						colorScheme={btnColorScheme[theme]}
						_hover={{ fontSize: "sm" }}
					/>
				</GridItem>
				<AlertDialog
					motionPreset="slideInBottom"
					leastDestructiveRef={cancelRef}
					onClose={() => {
						setValue(order.order.patientInfo.medicalRecordNumber);
						onClose();
					}}
					isOpen={isOpen}
					isCentered
				>
					<AlertDialogOverlay />

					<AlertDialogContent>
						<AlertDialogHeader>
							{diaglogText.Discard}
						</AlertDialogHeader>
						<AlertDialogCloseButton />
						<AlertDialogBody>
							<Flex
								direction={"column"}
								gap={"1rem"}
								fontSize={"large"}
							>
								<Input
									type={"number"}
									p={".5rem"}
									value={value}
									onChange={(e) => {
										setValue(e.target.value);
									}}
								/>
								<Text>
									{diaglogText.idmsg}{" "}
									{
										order.order.patientInfo
											.medicalRecordNumber
									}{" "}
									{diaglogText.to} {value}
								</Text>
							</Flex>
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								onClick={() => {
									setValue(
										order.order.patientInfo
											.medicalRecordNumber
									);
									onClose();
								}}
							>
								{buttonsText.No}
							</Button>
							<Button
								colorScheme="red"
								ml={3}
								onClick={submitHandler}
							>
								{buttonsText.yes}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			</SimpleGrid>
		</Box>
	);
}

export default EditPatienId;
