import React from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
const MonthCard = ({ contributionData }) => {
  // [
  //     5234, 6789, 7321, 4521, 8901, 4782, 6342, 5910, 3134, 7382, 6547, 9210,
  //   ]
  const currentMonthNumber = new Date().getMonth();
  //   let currentMonthNumber = 0;
  let color = "#999";
  let value = contributionData[currentMonthNumber];
  let prefix = "";
  let lastMonth = -1;
  let lastMonthVal = -1;
  let currVal = 0;

  if (currentMonthNumber > 0) {
    lastMonth = currentMonthNumber - 1;
    lastMonthVal = contributionData[lastMonth];
    currVal = contributionData[currentMonthNumber] - lastMonthVal;
    if (currVal > 0) {
      color = "#3f8600";
      value = currVal;

      prefix = <ArrowUpOutlined />;
    } else if (currVal < 0) {
      color = "#cf1322";
      value = currVal;
      prefix = <ArrowDownOutlined />;
    } else {
      value = 0;
    }
  }
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title="profits"
            value={value}
            precision={2}
            valueStyle={{
              color: color,
            }}
            prefix={prefix}
            suffix="€"
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title="profits"
            value={
              lastMonth === -1
                ? 0
                : (currVal * 100) / (lastMonthVal === 0 ? 100 : lastMonthVal)
            }
            precision={2}
            valueStyle={{
              color: color,
            }}
            prefix={prefix}
            suffix="%"
          />
        </Card>
      </Col>
    </Row>
  );
};
export default MonthCard;
