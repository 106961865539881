import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const AlignerStatistics = () => {
  const { aligner } = useSelector((store) => store.statistics);
  const { alignerLabels } = useSelector((store) => store.lang);

  const orderCount =
    aligner && aligner?.orderCount ? Object.values(aligner?.orderCount) : [];
  const upperCount =
    aligner && aligner?.upperCount ? Object.values(aligner?.upperCount) : [];
  const lowerCount =
    aligner && aligner?.lowerCount ? Object.values(aligner?.lowerCount) : [];
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: alignerLabels.orderCount,
        // data: [80, 160, 120, 180, 150, 200, 180, 210, 190, 220, 240, 230],
        data: orderCount,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: alignerLabels.lowerCount,
        // data: [50, 100, 75, 110, 90, 120, 105, 130, 120, 140, 150, 145],
        data: lowerCount,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: alignerLabels.upperCount,
        // data: [30, 60, 45, 70, 60, 80, 75, 80, 70, 80, 90, 85],
        data: upperCount,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Configuration options
  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default AlignerStatistics;
