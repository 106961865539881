import React from "react";
import allStaffSlice from "./features/user/AllStaffSlice";
import allclinicSlice from "./features/user/AllClinicsSlice";
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/UserSlice";
import orderSlice from "./features/order/orderSlice";
import ProductSlice from "./features/products/ProductSlice";
import allOrdersSlice from "./features/allOrders/allOrdersSlice";
import statistics from "./features/statistics/statisticsSlice";
import langSlice from "./features/language";
import binSlice from "./features/trash/trashSlice";
import notificationSlice from "./features/notification/notificationSlice";
import chatSlice from "./features/chat/chatSlice";
import adSlice from "./features/Ad";
import themesSlice from "./features/themes/themesSlice";
import InvoiceSlice from "./features/invoice/InvoiceSlice";
const store = configureStore({
  reducer: {
    invoice: InvoiceSlice,
    theme: themesSlice,
    user: userSlice,
    staff: allStaffSlice,
    clinic: allclinicSlice,
    order: orderSlice,
    products: ProductSlice,
    orders: allOrdersSlice,
    statistics: statistics,
    lang: langSlice,
    bin: binSlice,
    notification: notificationSlice,
    chat: chatSlice,
    ad: adSlice,
  },
});
export default store;
