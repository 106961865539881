import {
  GridItem,
  SimpleGrid,
  useColorMode,
  Text,
  Flex,
  Box,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { FaShoppingCart } from "react-icons/fa";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getProductStatistics } from "../features/statistics/statisticsSlice";
import Loading from "../components/Loading";
import { colors } from "../utils/Tools";
import { setActivePage } from "../features/chat/chatSlice";
import { logOut } from "../features/user/UserSlice";
import { Pie } from "react-chartjs-2";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

export default function ProductsStats() {
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

  const dispatch = useDispatch();
  const { user: me } = useSelector((store) => store.user);
  useEffect(() => {
    if (me === undefined) dispatch(logOut());
  }, [me]);
  useEffect(() => {
    dispatch(setActivePage(1));
  }, []);
  const { isLoading, products } = useSelector((store) => store.statistics);
  const { language } = useSelector((store) => store.lang);
  const { colorMode } = useColorMode();
  const lang = language === "English" ? 0 : 1;

  const productsBool = Array.from(
    { length: Math.max(products.length, 100) },
    (e) => true
  );

  const [productsState, setProductsState] = useState(productsBool);
  // console.log(productsState);
  const viewProductHandler = (index) => {
    setProductsState((prevState) => {
      const newProductsState = [...prevState];
      newProductsState[index] = !newProductsState[index];
      return newProductsState;
    });
  };
  useEffect(() => {
    dispatch(getProductStatistics());
  }, [dispatch]);
  if (isLoading) return <Loading />;
  return (
    <SimpleGrid columns={10}>
      <GridItem colSpan={10}>
        <Flex gap={"1rem"} flexWrap={"wrap"} marginTop={"2rem"}>
          {products?.map((e, index) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="22vh"
                //   bg="gray.100"
              >
                <Flex
                  flexDir={"column"}
                  position="relative"
                  width="150px"
                  height="150px"
                  rounded={"md"}
                  // bgImg={img}
                  boxShadow={"md"}
                  outline={"1px solid gray"}
                >
                  <Text
                    p={"1rem"}
                    fontSize="md"
                    w={"100%"}
                    fontWeight="semibold"
                    textAlign={"center"}
                    //   color="gray.700"
                    position="absolute"
                    top="20px"
                    left="50%"
                    transform="translateX(-50%)"
                    zIndex="10"
                    // bgColor={colorMode === "dark" ? "#635E6C" : "#DFDBE5"}
                  >
                    {e.productName[lang].Name}
                  </Text>
                  <Flex
                    justifySelf={"center"}
                    alignSelf={"center"}
                    p={".4rem"}
                    rounded={"md"}
                    width={"90%"}
                    bgColor={colorMode === "dark" ? "#BB8493" : "#F2F1EB"}
                    pos={"absolute"}
                    bottom={"10px"}
                    // left={"5px"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    gap={".6rem"}
                  >
                    <Flex
                      fontWeight={"semibold"}
                      alignItems={"center"}
                      fontSize={"1rem"}
                      gap={".4rem"}
                    >
                      <Text>{e.count}</Text>
                      <FaShoppingCart />
                    </Flex>
                    <IconButton
                      onClick={() => {
                        viewProductHandler(index);
                      }}
                      variant={"outline"}
                      icon={
                        productsState[index] ? <ViewIcon /> : <ViewOffIcon />
                      }
                    ></IconButton>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </GridItem>
      {products?.map((product, index) => {
        return productsState[index] === false ? (
          ""
        ) : (
          <GridItem colSpan={10}>
            <SimpleGrid columns={10} border={"1px solid #eee"} padding={"1rem"}>
              <GridItem colSpan={10}>
                <Text>{product?.productName[lang].Name}</Text>
              </GridItem>

              {product?.questions?.length > 0 &&
                product?.count > 0 &&
                product?.questions?.map((e) => {
                  let total = 0;
                  e?.answers?.forEach((ans) => {
                    total += ans.count;
                  });
                  return (
                    <GridItem colSpan={4} minH={"360px"}>
                      <Pie
                        options={{
                          plugins: {
                            legend: {
                              position: "bottom",
                              labels: {
                                color: colorMode === "dark" ? "white" : "black",
                                padding: 10,
                              },
                            },
                            title: {
                              display: true,
                              color: colorMode === "dark" ? "white" : "black",
                              text: e.question[lang].Name,
                              padding: {
                                top: 10,
                                bottom: 30,
                              },
                            },
                            tooltip: {
                              callbacks: {
                                label: function (tooltipItem) {
                                  const data = tooltipItem.dataset.data;
                                  // const total = data.reduce(
                                  //   (sum, value) => sum + value,
                                  //   0
                                  // );
                                  const value = data[tooltipItem.dataIndex];
                                  // const percentage = (
                                  //   (value / total) *
                                  //   100
                                  // ).toFixed(2);
                                  // return `${value}: ${percentage}%`;
                                  return value;
                                },
                              },
                            },
                            datalabels: {
                              formatter: (value, context) => {
                                const dataset = context.dataset.data;
                                const total = dataset.reduce(
                                  (sum, val) => sum + val,
                                  0
                                );
                                const percentage = (
                                  (value / total) *
                                  100
                                ).toFixed(2);
                                return percentage > 0 ? `${percentage}%` : "";
                              },
                              color: colorMode === "dark" ? "white" : "black",
                              // font: {
                              //   weight: "bold",
                              // },
                              anchor: "end",
                              align: "start",
                            },
                          },
                        }}
                        data={{
                          labels: e?.answers.map((ans) => {
                            return ans.answer[lang].Name;
                          }),

                          datasets: [
                            {
                              data: e?.answers.map((ans) => {
                                return ans.count;
                              }),
                              backgroundColor: colors,
                            },
                          ],
                        }}
                      />
                    </GridItem>
                  );
                })}
            </SimpleGrid>
          </GridItem>
        );
      })}
    </SimpleGrid>
  );
}
