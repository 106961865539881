import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTheError } from "../../utils/ErorrHandling";
import { BASEURL, STATS } from "../../utils/urls";
import { message } from "antd";

const initialState = {
  isLoading: false,
  results: {},
  bars: {},
  donut: {},
  lines: {},
  products: [],
  aligner: {},
  invoice: {},
};

export const getStatistics = createAsyncThunk(
  "statistics/getStatistics",
  async (year, thunkAPI) => {
    try {
      // Retrieve the token from the Redux store state
      // const token = thunkAPI.getState().user.user.token;

      // Ensure the token is valid before making the API request
      // if (!token) {
      // 	throw new Error("Invalid or missing user token.");
      // }

      const resp = await axios.get(
        STATS +
          `${
            thunkAPI.getState().user.user.role === "Clinic"
              ? "getmystatistics"
              : "getstatistics"
          }?year=${year}`,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getAligner = createAsyncThunk(
  "statistics/getAligner",
  async (year, thunkAPI) => {
    try {
      const resp = await axios.get(
        STATS + `get-Alginer-Statistics?year=${year}`,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getInvoiceStats = createAsyncThunk(
  "statistics/getInvoiceStats",
  async (year, thunkAPI) => {
    try {
      const resp = await axios.get(
        STATS + `get-invoice-Statistics?year=${year}`,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getProductStatistics = createAsyncThunk(
  "statistics/getProductStatistics",
  async (_, thunkAPI) => {
    try {
      // Retrieve the token from the Redux store state
      // const token = thunkAPI.getState().user.user.token;

      // Ensure the token is valid before making the API request
      // if (!token) {
      // 	throw new Error("Invalid or missing user token.");
      // }

      const resp = await axios.get(STATS + `get-product-statistics`, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getProductStatisticsFiltred = createAsyncThunk(
  "statistics/getProductStatisticsFiltred",
  async (data, thunkAPI) => {
    try {
      data.startDate = data.startDate ? data.startDate : null;
      data.endDate = data.endDate ? data.endDate : null;
      // console.log(data);
      const resp = await axios.post(
        STATS + `get-product-statistics-filterd`,
        data,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    clearProductsFromStats(state) {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatistics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStatistics.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // console.log(payload?.statistics?.count);
      state.results = payload?.statistics?.count;
      state.bars = payload?.statistics?.months;
      state.lines = payload?.statistics?.topProducts;
    });
    builder.addCase(getStatistics.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(getAligner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAligner.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      state.aligner = payload;
    });
    builder.addCase(getAligner.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(getProductStatistics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductStatistics.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // console.log(payload);
      state.products = payload;
    });
    builder.addCase(getProductStatistics.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(getProductStatisticsFiltred.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getProductStatisticsFiltred.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // console.log(payload);
        state.uis = [];
        state.products = payload;
      }
    );
    builder.addCase(
      getProductStatisticsFiltred.rejected,
      (state, { payload }) => {
        state.isLoading = false;

        message.error(payload);
      }
    );
    builder.addCase(getInvoiceStats.pending, (state) => {});
    builder.addCase(getInvoiceStats.fulfilled, (state, { payload }) => {
      state.invoice = payload;
    });
    builder.addCase(getInvoiceStats.rejected, (statem, { payload }) => {});
  },
});

export const { clearProductsFromStats } = statisticsSlice.actions;
export default statisticsSlice.reducer;
