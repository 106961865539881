import {
  Button,
  Flex,
  Icon,
  Input,
  Textarea,
  Box,
  InputGroup,
  InputRightElement,
  Container,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { useSelector } from "react-redux";
import img from "../../assets/images/chat.jpg";
import TextEditor from "../../pages/TextEditor";
let isTyping = false;
function ChatControle({ sendMessage, type, see }) {
  const { id } = useSelector((store) => store.chat);
  const user = useSelector((store) => store.user.user);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (message.trim() !== "" && isTyping === false) {
      type(true);
      isTyping = true;
    }
    if (message.trim() === "" && isTyping === true) {
      type(false);
      isTyping = false;
    }
  }, [message]);
  const changeHandler = (e) => {
    setMessage(e);
  };
  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter" && !e.shiftKey) {
  //     e.preventDefault(); // Prevent default behavior of creating a new line

  //     if (message.trim() !== "") {
  //       // Remove trailing newlines or <br> tags
  //       let modifiedMessage = message.replace(/<p><br><\/p>$/g, "").trim(); // Trim empty <p><br></p>
  //       modifiedMessage = modifiedMessage.replace(/\n$/, ""); // Trim newline characters

  //       // Send the modified message
  //       sendMessage(modifiedMessage);

  //       // Clear the editor
  //       setMessage("");
  //     }
  //   }
  // };
  return (
    <Flex alignItems={"center"} justifyContent={"center"}>
      <InputGroup w={["100%", "100%", "80%", "60%"]} position="relative">
        <TextEditor changeHandler={changeHandler} message={message} />
        <Button
          colorScheme="purple"
          position="absolute"
          variant={"ghost"}
          bottom="2rem" // Adjust as necessary for positioning
          right="0rem" // Adjust as necessary for positioning
          onClick={() => {
            if (message.trim() !== "") {
              sendMessage(message);
            }
            setMessage("");
          }}
        >
          <IoIosSend />
        </Button>
      </InputGroup>
    </Flex>
  );
}

export default ChatControle;
