import React, { useEffect } from "react";
import { VStack, Flex, useColorMode } from "@chakra-ui/react";
import ProductName from "../components/ProductName";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  changeActivation,
} from "../features/products/ProductSlice";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { setActivePage } from "../features/chat/chatSlice";
import { logOut } from "../features/user/UserSlice";

import { IoCreateOutline } from "react-icons/io5";
import { FloatButton } from "antd";

function AddOrderOption() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(100));
  }, []);

  const toggleActivation = (item) => {
    dispatch(changeActivation(item));
  };
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);
  const { products, isLoading } = useSelector((store) => store.products);

  // const { product } = useSelector((store) => store.lang);
  const { user } = useSelector((store) => store.user);

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);
  if (user.role !== "Admin") return <Navigate to={"/404"} />;
  if (isLoading) return <Loading />;
  // console.log(products);
  return (
    <VStack
      position={"relative"}
      // bgColor={"#F5F5F5"}
      // bgColor={colorMode === "dark" ? "#635E6C" : "#DFDBE5"}
      boxShadow={"md"}
      p={"1rem"}
      alignItems={"start"}
      gap={"2rem"}
    >
      <FloatButton
        style={{
          position: "absolute",
        }}
        onClick={() => {
          Navigate("/product");
        }}
        icon={<IoCreateOutline />}
      ></FloatButton>

      <Flex gap={"1rem"} wrap={"wrap"}>
        {products?.map((item, index) => (
          <ProductName
            key={index}
            item={item}
            toggleActivation={toggleActivation}
          />
        ))}
      </Flex>
    </VStack>
  );
}

export default AddOrderOption;
