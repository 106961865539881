import {
  Flex,
  Button,
  Badge,
  Select,
  option,
  Checkbox,
  CheckboxGroup,
  Tag,
  SimpleGrid,
  GridItem,
  Text,
} from "@chakra-ui/react";
// import { Select, Typography } from "antd";
import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeState } from "../../features/chat/chatSlice";
// const { Title } = Typography;

function FilterChats({ apply, states2 }) {
  const { states, controlers } = useSelector((store) => store.lang);
  // console.log(options, states);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    // console.log(e.target.value);
    dispatch(changeState(e.target.value));
  };
  return (
    <SimpleGrid
      columns={2}
      padding={"1rem"}
      gap={".2rem"}
      boxShadow={"0px 1px 3px rgba(0, 0, 0, 0.5) inset"}
      borderRadius={"10px"}
      marginBottom={"1rem"}
    >
      {/* <CheckboxGroup colorScheme={"green"}> */}
      {states2.map((item, index) => {
        // console.log(item);
        return (
          <GridItem colSpan={1} key={index}>
            <Tag
              // rounded={"full"}
              width={"100%"}
              // fontSize={".6rem"}
            >
              <Checkbox
                iconSize={"xs"}
                colorScheme={"gray"}
                size={"sm"}
                // variant={"circular"}
                // rounded={"full"}
                // boxShadow={
                // 	"inset -2px -2px 5px white, inset 3px 3px 5px rgba(0, 0, 0, 0.1)"
                // }
                // fontSize={".5rem"}
                p={".2rem"}
                // width={"50%"}
                value={index}
                onChange={handleChange}
                isChecked={item === 1}
              >
                <Text fontSize={"smaller"}>{states[index]}</Text>
              </Checkbox>
            </Tag>
          </GridItem>
        );
      })}
      {/* </CheckboxGroup> */}
    </SimpleGrid>
  );
}

export default FilterChats;
