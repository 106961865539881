import React, { useState, useEffect } from "react";
import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Center,
  SimpleGrid,
  useDisclosure,
  Checkbox,
  GridItem,
  Flex,
  IconButton,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

import Order from "../../components/Order";
import { useColorMode } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrdersState,
  getOrders,
  handleChange,
} from "../../features/allOrders/allOrdersSlice";
import { Empty, Pagination } from "antd";

import Loading from "../../components/Loading";
import HeadingTitle from "../../components/order/HeadingTitle";

import { logOut } from "../../features/user/UserSlice";
import AccountantFilters from "./AccountantFilters";

const getDate = (createdDate) => {
  let utcTime = new Date(createdDate);
  let timezoneOffset = new Date().getTimezoneOffset();

  // Convert UTC time to local time
  let localTime = new Date(utcTime.getTime() - timezoneOffset * 60 * 1000);
  const dateObject = new Date(localTime);

  // Format the date as "DD/MM/YYYY"
  const formattedDate = dateObject.toLocaleDateString("en-GB");
  return formattedDate;
};
// let CheckedOrderes = new Object();

function AllDeliverdOrders() {
  const [CheckedOrderes, setCheckedOrderes] = useState({ orders: [], cnt: 0 }); // Initialize with an empty object
  // const [cnt, setCnt] = useState(CheckedOrderes.length);
  const [shippingPP, setShippingPP] = useState(false);

  const handleCheckOrder = (e, orderId, clinicId) => {
    if (e.target.checked === false) {
      let updatedOrders = CheckedOrderes.orders.filter((order) => {
        return order.orderId != orderId;
      });
      setCheckedOrderes({ orders: updatedOrders, cnt: updatedOrders.length });
    } else {
      setCheckedOrderes((prevState) => ({
        orders: [
          ...prevState.orders,
          {
            orderId,
            clinicId,
            orderHistoryRequest: {
              updateDescription: `Changes the state from ${
                states[e.state]
              } to ${states[4]}`,
            },
          },
        ],
        cnt: prevState.cnt + 1,
      }));
    }
  };

  const { states, orderLabels } = useSelector((store) => store.lang);

  function doNothing() {}
  const {
    pages,
    productId,
    orders,

    isLoading,
    state,
    search,
    startDate,
    endDate,
    sortedAsc,
    priority,
    itemPerPage,
    updateIsLoading,
  } = useSelector((store) => store.orders);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(itemPerPage); // Default page size

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setShippingPP(false);
  };

  const handleSizeChange = (current, size) => {
    setPageSize(size);
    setShippingPP(false);
    dispatch(handleChange({ name: "itemPerPage", value: size }));
    setCurrentPage(1); // Reset to the first page when the page size changes
  };

  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);

  useEffect(() => {
    if (updateIsLoading === false)
      dispatch(
        getOrders({
          page: currentPage,
          itemPerPage: pageSize,
          state: "delivred",
        })
      );
  }, [
    updateIsLoading,
    search,
    state,
    startDate,
    endDate,
    sortedAsc,
    currentPage,
    pageSize,
    productId,
    priority,
  ]);

  const { allOrders, empty } = useSelector((store) => store.lang);
  const { colorMode } = useColorMode();
  let items =
    orders === undefined || orders.length === 0
      ? []
      : orders?.orders?.map((item, index) => {
          let bcolor = `${
            index % 2 === 0
              ? `${colorMode === "dark" ? "gray.700" : "gray.50"}`
              : `${colorMode === "dark" ? "gray.800" : "gray.100"}`
          }`;

          return (
            <Order
              handler={handleCheckOrder}
              CheckboxIsVisible={shippingPP && item.state === 3}
              key={index}
              bcolor={bcolor}
              order={item}
            ></Order>
          );
        });
  // const doc = new jsPDF();

  if (isLoading || updateIsLoading) return <Loading />;
  const clinicCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    fnameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    snameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    recordCols = user.role === "Clinic" ? 2 : 2,
    statesCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    dateCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    productsCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2];

  const clinicDisplay = user.role === "Clinic" ? "none" : "block",
    fnameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    snameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    recordDisplay = user.role === "Clinic" ? "block" : "block",
    statesDisplay =
      user.role === "Clinic" ? "block" : ["block", "block", "block", "block"],
    dateDisplay =
      user.role === "Clinic" ? "block" : ["block", "block", "block", "block"],
    productsDisplay =
      user.role === "Clinic" ? "block" : ["block", "block", "block", "block"];
  return (
    <>
      <AccountantFilters />
      <SimpleGrid
        height={"100%"}
        overflow={"scroll"}
        columns={user.role === "Clinic" ? 12 : 15}
        alignItems={"left"}
        w={"100%"}
        gap={"1rem"}
        // my={"1rem"}
        // justifyContent={"center"}
        color={colorMode === "dark" ? "gray.100" : "gray.500"}
        mt={"1rem"}
        // display={"grid"}
      >
        <HeadingTitle
          value={allOrders.clinicName}
          cols={clinicCols}
          display={clinicDisplay}
        />

        <HeadingTitle
          value={allOrders.fName}
          cols={fnameCols}
          display={fnameDisplay}
        />
        <HeadingTitle
          value={allOrders.sName}
          cols={snameCols}
          display={snameDisplay}
        />
        <HeadingTitle
          value={allOrders.idNumber}
          cols={recordCols}
          display={recordDisplay}
        />
        <HeadingTitle
          value={allOrders.date}
          cols={dateCols}
          display={dateDisplay}
        />

        <HeadingTitle
          value={allOrders.state}
          cols={statesCols}
          display={statesDisplay}
        />
        <HeadingTitle
          value={allOrders.producs}
          cols={productsCols}
          display={productsDisplay}
        />
      </SimpleGrid>
      {items.length === 0 ? (
        <Center>
          <Empty description={empty.empty} />{" "}
        </Center>
      ) : (
        items
      )}

      <Center mt={"1rem"}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={pageSize * pages} // Replace with the actual total number of items
          onChange={handlePageChange}
          onShowSizeChange={handleSizeChange}
          showSizeChanger
          pageSizeOptions={["10", "20", "30", "40", "50"]} // Customize the available page sizes
        />
      </Center>
    </>
  );
}

export default AllDeliverdOrders;
