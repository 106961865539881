import React from "react";
import { Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
function FilterProducts({ value, handler, products }) {
  const { language } = useSelector((store) => store.lang);
  return (
    <Select value={value} w={"100%"} onChange={handler}>
      <option value={"all"}>all</option>
      {products?.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            {language === "English" ? item.name.en : item.name.de}
          </option>
        );
      })}
    </Select>
  );
}

export default FilterProducts;
