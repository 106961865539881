import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL, CLINIC } from "../../utils/urls";
import { getTheError } from "../../utils/ErorrHandling";
import { message } from "antd";
import { activateUser, deActivateUser } from "./UserSlice";
import { removeUserFromLocalStorage } from "../../utils/LocalStorage";
const initialFilters = {
  search: "",
};
const initialState = {
  isLoading: false,
  clinics: [],
  pages: 1,
  page: 1,
  itemPerPage: 10,
  ...initialFilters,
};
export const changepageClinic = createAsyncThunk(
  "clinic/changepageClinic",
  async (data, thunkAPI) => {
    try {
      let { search } = thunkAPI.getState().clinic;
      const resp = await axios.get(
        CLINIC +
          `getclinics?itemPerPage=${data.itemPerPage}&page=${data.page}` +
          (search !== "" ? `&searchTerm=${search}` : ""),
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      resp.data.data.page = data.page;
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

export const exportClinic = createAsyncThunk(
  "clinic/exportClinic",
  async (_, { getState, thunkAPI }) => {
    try {
      const response = await axios.get(`${BASEURL}/api/clinic/getexcelfile`, {
        responseType: "blob",
        headers: {
          Authorization: getState().user.user.token,
        },
      });

      const contentDispositionHeader = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const filename =
        matches && matches[1]
          ? matches[1].replace(/['"]/g, "")
          : "downloaded_clinic_file.xlsx";

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return { success: true };
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
const allclinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {
    applyFilters: (state, { payload }) => {
      state.search = payload?.search;
    },
    handleChange: (state, { payload }) => {
      state[payload?.name] = payload?.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changepageClinic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changepageClinic.fulfilled, (state, { payload }) => {
      if (payload !== undefined && payload?.data !== undefined) {
        state.pages = payload?.data.pages;
        state.page = payload?.data.page;
        state.clinics = payload?.data.clinics;
      }
      state.isLoading = false;
    });
    builder.addCase(changepageClinic.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(exportClinic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(exportClinic.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(exportClinic.rejected, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(deActivateUser.fulfilled, (state, { payload }) => {
      state.clinics = state.clinics.map((item) => {
        if (item.userId === payload) item.isActive = !item.isActive;
        return item;
      });
    });
    builder.addCase(activateUser.fulfilled, (state, { payload }) => {
      state.clinics = state.clinics.map((item) => {
        if (item.userId === payload) item.isActive = !item.isActive;
        return item;
      });
    });
  },
});
export const { applyFilters, handleChange } = allclinicSlice.actions;
export default allclinicSlice.reducer;
