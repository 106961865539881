import axios from "axios";
import { useEffect, useState } from "react";
// import "./test.css";
import { BASEURL } from "../utils/urls";
import { useSelector } from "react-redux";
import useSignalr from "../utils/signalRServices";
const chunkSize = 10 * 1024;
function Test() {
	// const [files, setFiles] = useState([]);
	// const [currentFileIndex, setCurrentFileIndex] = useState(null);
	// const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
	// const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
	// const { user } = useSelector((store) => store.user);

	// const handler = (e) => {
	// 	setFiles([...e.target.files]);
	// };
	// const connection = useSignalr();

	// const ReceiveFileBlock = async (byte, file) => {
	// 	try {
	// 		await connection.invoke(
	// 			"ReceiveFileBlock",
	// 			file.name,
	// 			"test",
	// 			byte
	// 		);
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// };
	// const uploadSuccess = async (file, folder) => {
	// 	try {
	// 		await connection.invoke("CompleteFileUpload", file.name, folder);
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// };
	// function readAndUploadCurrentChunk() {
	// 	// console.log(files);
	// 	const reader = new FileReader();
	// 	const file = files[currentFileIndex];
	// 	if (!file) {
	// 		return;
	// 	}
	// 	const from = currentChunkIndex * chunkSize;
	// 	const to = from + chunkSize;
	// 	const blob = file.slice(from, to);
	// 	reader.onload = (e) => uploadChunk(e);
	// 	reader.readAsDataURL(blob);
	// }

	// function uploadChunk(readerEvent) {
	// 	const file = files[currentFileIndex];
	// 	const data = readerEvent.target.result;
	// 	// const params = new URLSearchParams();
	// 	// params.set("name", file.name);
	// 	// params.set("size", file.size);
	// 	// params.set("currentChunkIndex", currentChunkIndex);
	// 	// params.set("totalChunks", Math.ceil(file.size / chunkSize));
	// 	// const headers = {
	// 	// 	"Content-Type": "application/octet-stream",
	// 	// 	authorization: user.token,
	// 	// };

	// 	// connection.in;

	// 	// const url = "http://localhost:4001/upload?" + params.toString();
	// 	// const url = BASEURL + "/api/user/profilemypic";
	// 	ReceiveFileBlock(data, file);
	// 	connection.on("RequestNextChunk", () => {
	// 		console.log(`chunk number : ${currentChunkIndex}`);
	// 		const file = files[currentFileIndex];
	// 		const filesize = files[currentFileIndex].size;
	// 		const chunks = Math.ceil(filesize / chunkSize) - 1;
	// 		console.log(chunks, currentChunkIndex);
	// 		const isLastChunk = currentChunkIndex === chunks;
	// 		if (isLastChunk) {
	// 			// file.finalFilename = response.data.finalFilename;
	// 			setLastUploadedFileIndex(currentFileIndex);
	// 			setCurrentChunkIndex(null);
	// 			uploadSuccess(file, "test");
	// 		} else {
	// 			setCurrentChunkIndex(currentChunkIndex + 1);
	// 		}
	// 	});
	// 	connection.on("FileUploadComplete", () => {
	// 		console.log("completed");
	// 	});
	// 	connection.on("HandleError", () => {
	// 		console.log("no");
	// 	});
	// 	connection.onclose((error) => {
	// 		console.warn("SignalR connection closed:", error);
	// 		// You can add additional logic here to handle reconnection or show a message to the user
	// 	});
	// 	// axios
	// 	// .post(url, data, { headers })
	// 	// .then((response) => {
	// 	// 	const file = files[currentFileIndex];
	// 	// 	const filesize = files[currentFileIndex].size;
	// 	// 	const chunks = Math.ceil(filesize / chunkSize) - 1;
	// 	// 	const isLastChunk = currentChunkIndex === chunks;
	// 	// 	if (isLastChunk) {
	// 	// 		file.finalFilename = response.data.finalFilename;
	// 	// 		setLastUploadedFileIndex(currentFileIndex);
	// 	// 		setCurrentChunkIndex(null);
	// 	// 	} else {
	// 	// 		setCurrentChunkIndex(currentChunkIndex + 1);
	// 	// 	}
	// 	// })
	// 	// .catch((error) => {
	// 	// 	console.log(error);
	// 	// });
	// }

	// useEffect(() => {
	// 	if (lastUploadedFileIndex === null) {
	// 		return;
	// 	}
	// 	const isLastFile = lastUploadedFileIndex === files.length - 1;
	// 	const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
	// 	setCurrentFileIndex(nextFileIndex);
	// }, [lastUploadedFileIndex]);

	// useEffect(() => {
	// 	if (files.length > 0) {
	// 		if (currentFileIndex === null) {
	// 			setCurrentFileIndex(
	// 				lastUploadedFileIndex === null
	// 					? 0
	// 					: lastUploadedFileIndex + 1
	// 			);
	// 		}
	// 	}
	// }, [files.length]);

	// useEffect(() => {
	// 	if (currentFileIndex !== null) {
	// 		setCurrentChunkIndex(0);
	// 	}
	// }, [currentFileIndex]);

	// useEffect(() => {
	// 	if (currentChunkIndex !== null) {
	// 		readAndUploadCurrentChunk();
	// 	}
	// }, [currentChunkIndex]);

	return (
		<div>
			<input
				multiple
				type={"file"}
				// onChange={handler}
			></input>
		</div>
	);
}

export default Test;
