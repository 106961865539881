import React, { useEffect, useState } from "react";
import { Center, Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Tooltip,
} from "antd";
import UserCard from "../components/UserCard";
import Loading from "../components/Loading";
import {
  changepageClinic,
  handleChange,
} from "../features/user/AllClinicsSlice";
import {
  deActivateUser,
  activateUser,
  logOut,
  updateClinic,
} from "../features/user/UserSlice";
import { setActivePage } from "../features/chat/chatSlice";
const colors = [
  "rgba(255, 99, 132, 8)",
  "rgba(255, 159, 64, 8)",
  "rgba(255, 205, 86, 8)",
  "rgba(75, 192, 192, 8)",
  "rgba(54, 162, 235, 8)",
  "rgba(153, 102, 255, 8)",
  "rgba(201, 203, 207, 8)",
];
function AllClinics() {
  const { pages, search, clinics, isLoading, itemPerPage } = useSelector(
    (store) => store.clinic
  );
  const { empty } = useSelector((store) => store.lang);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(itemPerPage); // Default page size
  const ActivationHandler = (user) => {
    // console.log(user);
    if (user.isActive) {
      dispatch(deActivateUser(user));
    } else {
      dispatch(activateUser(user));
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPageSize(size);
    dispatch(handleChange({ name: "itemPerPage", value: size }));
    setCurrentPage(1); // Reset to the first page when the page size changes
  };
  const { user, activationLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);
  useEffect(() => {
    dispatch(setActivePage(4));
  }, []);
  useEffect(() => {
    dispatch(
      changepageClinic({
        itemPerPage: pageSize,
        page: currentPage,
        search: search,
      })
    );
  }, [search, pageSize, currentPage]);

  const { buttonsText } = useSelector((store) => store.lang);
  // if (isLoading) return ;

  let users = [];
  if (clinics !== undefined && clinics.length > 0) {
    users = clinics.map((user, index) => {
      // const min = 0;
      // const max = 7;
      // const r = Math.round(min + Math.random() * (max - min));
      let color = colors[index % 7];
      // console.log(this, user);
      return (
        <UserCard
          key={index}
          color={color}
          Role={"clinic"}
          info={user}
          handler={ActivationHandler}
        />
      );
    });
  }

  return (
    <>
      {isLoading || activationLoading ? (
        <Loading />
      ) : users?.length > 0 ? (
        <App />
      ) : (
        <Empty description={empty.empty} />
      )}
      <Center marginTop={"1rem"}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={pageSize * pages} // Replace with the actual total number of items
          onChange={handlePageChange}
          onShowSizeChange={handleSizeChange}
          showSizeChanger
          pageSizeOptions={["10", "20", "30", "40", "50"]} // Customize the available page sizes
        />
      </Center>
    </>
  );
}

export default AllClinics;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const App = ({ handler }) => {
  const { updateUser } = useSelector((store) => store.lang);
  const dispatch = useDispatch();
  const ActivationHandler = (user) => {
    // console.log(user);
    if (user.isActive) {
      dispatch(deActivateUser(user));
    } else {
      dispatch(activateUser(user));
    }
  };
  const { userText, buttonsText, role } = useSelector((store) => store.lang);
  const { user } = useSelector((store) => store.user);
  const originData = [];
  const [form] = Form.useForm();
  const { clinics } = useSelector((store) => store.clinic);
  clinics.forEach((element) => {
    originData.push({
      key: element.id,
      name: element.name,
      fullLocation: element.fullLocation,
      phone: element.phone,
      country: element.country,
      item: element,
      activation: (
        // user.role === "Admin" ? (
        <Button
          display={element.userId === user.userId ? "none" : "block"}
          colorScheme={element.isActive ? "green" : "red"}
          onClick={() => {
            ActivationHandler(element);
          }}
          rounded={"md"}
        >
          {element.isActive ? buttonsText.activate : buttonsText.inactivate}
        </Button>
      ),
      action: (
        <Link to="/update-user" state={{ user: element }}>
          <Button
            onClick={() => {}}
            // w={"full"}
            variant={"outline"}
            rounded={"md"}
          >
            {userText.moreInfo}
          </Button>
        </Link>
      ),
    });
  });
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      country: "",
      phone: "",
      fullLocation: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        // console.log(row, newData[index]);
        const item = newData[index];
        let curItem = { ...item.item };
        curItem.country = row.country;
        curItem.fullLocation = row.fullLocation;
        curItem.phone = row.phone;
        curItem.name = row.name;
        // curItem.fullLocation = "123";
        // console.log(curItem);
        dispatch(updateClinic(curItem));
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      // console.log("Validate Failed:", errInfo);
    }
  };
  const columns = [
    {
      title: updateUser.name,
      dataIndex: "name",
      width: "15%",
      editable: true,
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },

    {
      title: updateUser.country,
      dataIndex: "country",
      width: "10%",
      editable: true,
      render: (country) => (
        <Tooltip placement="topLeft" title={country}>
          {country}
        </Tooltip>
      ),
    },
    {
      title: updateUser.phone,
      dataIndex: "phone",
      width: "15%",
      editable: true,
      render: (phone) => (
        <Tooltip placement="topLeft" title={phone}>
          {phone}
        </Tooltip>
      ),
    },
    {
      title: updateUser.adress,
      dataIndex: "fullLocation",
      width: "25%",
      editable: true,
      render: (fullLocation) => (
        <Tooltip placement="topLeft" title={fullLocation}>
          {fullLocation}
        </Tooltip>
      ),
    },
  ];
  if (user.role === "Admin") {
    columns.push({
      title: updateUser.operation,
      dataIndex: "operation",

      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginInlineEnd: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    });
    columns.push({
      title: updateUser.activation,
      dataIndex: "activation",
      width: "10%",
    });
  }
  columns.push({
    title: updateUser.action,
    dataIndex: "action",
    width: "10%",
  });
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};
