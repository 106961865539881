import React, { useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../features/chat/chatSlice";
const { Paragraph, Text } = Typography;

const Faild = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(100));
  }, []);
  const { error, buttonsText } = useSelector((store) => store.lang);
  return (
    <Result
      status="error"
      title={error.faild}
      // subTitle="Please check and modify the following information before resubmitting."
      extra={[
        <Link to={"/"}>
          <Button type="primary" key="console">
            {buttonsText.backButton}
          </Button>
        </Link>,
        // <Button key="buy">Buy Again</Button>,
      ]}
    >
      {/* <div className="desc">
			<Paragraph>
				<Text
					strong
					style={{
						fontSize: 16,
					}}
				>
					The content you submitted has the following error:
				</Text>
			</Paragraph> 
			 <Paragraph>
				<CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
				Your account has been frozen. <a>Thaw immediately &gt;</a>
			</Paragraph>
			<Paragraph>
				<CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
				Your account is not yet eligible to apply.{" "}
				<a>Apply Unlock &gt;</a>
			</Paragraph>
		</div> */}
    </Result>
  );
};
export default Faild;
