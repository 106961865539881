import {
  useColorMode,
  Flex,
  Text,
  Spacer,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import React from "react";
import { GiBugleCall } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { deleteAd } from "../features/Ad";
import BtnLoading from "./BtnLoading";
import NewText from "../pages/NEwText";

import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

function Anouncement() {
  const { colorMode } = useColorMode();
  const { theme, btnColorScheme, shadeColor, mainColor } = useSelector(
    (store) => store.theme
  );
  // const { user } = useSelector((store) => store.user);
  // const dispatch = useDispatch();
  const { ad, id, isLoading } = useSelector((store) => store.ad);
  if (isLoading) return <BtnLoading />;
  if (ad === undefined) return <></>;
  return (
    <BasicUsage content={<NewText rawContent={ad} />} />

    // <Flex
    //   h={16}

    //   gap={"2rem"}
    //   bgColor={colorMode === "dark" ? "#635E6C" : "white"}
    //   paddingX={"1rem"}
    //   rounded={"md"}
    //   alignItems={"center"}
    // >
    //   <Icon minW={"2rem"}>
    //     <GiBugleCall size={"1.5rem"} color={btnColorScheme[theme]} />
    //   </Icon>

    /* <Text
        fontSize={".9rem"}
        letterSpacing={1.1}
        // color={colorMode === "dark" ? "white" : "purple.600"}
        fontWeight={"semibold"}
      >
        {ad}
      </Text> */
    //   <Spacer></Spacer>
    //   {user.role === "Admin" && (
    //     <IconButton
    //       onClick={() => {
    //         dispatch(deleteAd(id));
    //       }}
    //       icon={<IoMdClose color="red" size={"1.5rem"} cursor={"pointer"} />}
    //     />
    //   )}
    // </Flex>
  );
}

export default Anouncement;

function BasicUsage({ content }) {
  const { id } = useSelector((store) => store.ad);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  return (
    <>
      {user.role !== "Clinic" && (
        <IconButton
          icon={<GiBugleCall size={"2rem"} color={"#5E1675"} />}
          onClick={onOpen}
          colorScheme="purple"
          variant={"outline"}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody minH={"25vh"}>{content}</ModalBody>
          {user.role === "Admin" && (
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  dispatch(deleteAd(id));
                }}
              >
                Remove
              </Button>
            </ModalFooter>
          )}{" "}
        </ModalContent>
      </Modal>
    </>
  );
}
