import { TabPanel } from "@chakra-ui/react";
import React, { useEffect } from "react";
import NoneEditableOrderBox from "./NonEditableOrderBox";

import {
	castMyUpdatedData,
	castUpdatedData,
} from "../../features/order/orderSlice";
import EditOrderName from "./EditOrderName";
import { useDispatch, useSelector } from "react-redux";
import EditPatienId from "./EditPatientId";
import EditOrderName2 from "./EditOrderName2";
import EditPatienDate from "./EditPatienDate";
function ClinicInfo({ isOkToClinic, id }) {
	const { user } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(castMyUpdatedData());
		dispatch(castUpdatedData());
	}, [dispatch]);

	const {
		updatedData,
		myUpdatedData,
		order: {
			clinic: { UserName: clinicUsername },
		},
	} = useSelector((store) => store.order);
	const { orderLabels, roleText, diaglogText } = useSelector(
		(store) => store.lang
	);
	let order =
		user.role === "Clinic"
			? Object.assign(myUpdatedData)
			: Object.assign(updatedData);
	return (
		<TabPanel>
			{user.role !== "Clinic" && (
				<NoneEditableOrderBox
					title={roleText.clinic}
					value={clinicUsername}
				/>
			)}
			{isOkToClinic || user.role === "Admin" ? (
				<EditOrderName id={id} />
			) : (
				<NoneEditableOrderBox
					title={orderLabels.firstName}
					value={order.order.patientInfo.firstName}
				/>
			)}
			{isOkToClinic || user.role === "Admin" ? (
				<EditOrderName2 id={id} />
			) : (
				<NoneEditableOrderBox
					title={orderLabels.lastName}
					value={order.order.patientInfo.lastName}
				/>
			)}
			{isOkToClinic || user.role === "Admin" ? (
				<EditPatienId id={id} />
			) : (
				<NoneEditableOrderBox
					title={orderLabels.id}
					value={order.order.patientInfo.medicalRecordNumber}
				/>
			)}
			{isOkToClinic || user.role === "Admin" ? (
				<EditPatienDate id={id} />
			) : (
				<NoneEditableOrderBox
					title={orderLabels.birthDate}
					value={order.order.patientInfo.dateOfBirth}
				/>
			)}
		</TabPanel>
	);
}

export default ClinicInfo;
