import {
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  RadioGroup,
  Text,
  // MenuGroup,
  // MenuDivider,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import Answer from "./Answer";
import { useDispatch, useSelector } from "react-redux";
import AnswersWithoutInput from "./AnswersWithoutInput";
import RadioAnswers from "./RadioAnswers";
import CheckBoxGroup from "./CheckBoxGroup";

function QuestionsWithoutInput({ item, num }) {
  const { language } = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  return (
    // <Collapse>
    <Flex>
      <Flex
        minW={"480px"}
        // overflow={"scroll"}
        // paddingBottom={"1rem"}
        boxShadow={"sm"}
        flexDir={"column"}
        width={"100%"}
        gap={"1rem"}
        p={"1rem"}
      >
        <Text
          p={".5rem"}
          // bgColor={"#efefef"}
          fontStyle={"italic"}
          fontWeight={"semibold"}
        >
          {language === "English" ? item.value.en : item.value.de}
        </Text>

        <Flex flexDir={"column"} gap={".5rem"}>
          {item.type === 1 ? (
            <RadioAnswers item={item} />
          ) : (
            <CheckBoxGroup item={item}> </CheckBoxGroup>
          )}
        </Flex>
      </Flex>
    </Flex>
    // </Collapse>
  );
}

export default QuestionsWithoutInput;
