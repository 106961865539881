import { GridItem, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
function HeadingTitle({ display, cols, value, state = true, stateValue = "" }) {
	return (
		<GridItem colSpan={cols} display={display} p={".4rem"} alignContent={"center"}>
			{state ? (
				<Tooltip label={value} placement="top">
					<Text
						fontSize={".9rem"}
						fontWeight={"semi-bold"}
						textOverflow={"ellipsis"}
						overflow={"clip"}
						size="sm"
						height={"1.6rem"}
					>
						{value}
					</Text>
				</Tooltip>
			) : (
				<Tooltip label={stateValue} placement="top">
					{value}
				</Tooltip>
			)}
		</GridItem>
	);
}

export default HeadingTitle;
